import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {device, deviceRequests} from '../../helpers/types';
import styles from "./css/DeviceRequests.module.css";
import Loader from "../../utilities/Loader";
import Context from "../../helpers/Context";
import {getDeviceRequestsByID, manageRequestByID} from "../../helpers/APIEnpoints";
import {useTranslation} from "react-i18next";
import {extractFullNameFromEmail} from "../../helpers/helperFunctions";
import Button from "../../utilities/Button";
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    deviceID: string;
    devices: device[];
    setDevices: Dispatch<SetStateAction<device[]>>;
}

const DeviceRequests: FC<IProps> = ({deviceID, devices, setDevices}) =>
{
    const {notificationDispatch} = useContext(Context);
    const [deviceRequest, setDeviceRequests] = useState<deviceRequests[]>([]);

    const [readMoreMessage, setReadMoreMessage] = useState<string | null>(null);

    const [requestID, setRequestID] = useState<string>("");
    const [decision, setDecision] = useState<"" | "rejected" | "accepted">("");
    const [requestUser, setRequestUser] = useState<string>("");
    const [adminMessage, setAdminMessage] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {t} = useTranslation();

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        (async () =>
        {
            const requests = await getDeviceRequestsByID(deviceID, notificationDispatch, {accounts: accounts, instance: instance});
            if(requests) setDeviceRequests(requests);
            setIsLoading(false)
        })();
    }, []);

    const handleDecision = async () =>
    {
        const managedRequest = await manageRequestByID({_id: requestID, adminMessage: adminMessage, decision: (decision as "rejected" | "accepted")}, notificationDispatch, {accounts: accounts, instance: instance});

        if(managedRequest)
        {
            const index = devices.findIndex(device => device._id === deviceID);
            setDevices(current =>
            {
                current[index] = managedRequest.device;
                return current;
            })

            setDeviceRequests(managedRequest.device.requests as deviceRequests[]);
            setDecision("");
        }
    }

    const handleCloseDecision = () =>
    {
        setDecision("");
        setAdminMessage("");
    }

    const handleConfirmDecisionTitle = () =>
    {
        switch (decision)
        {
            case "accepted":
                return t("app.accept").toLowerCase()
            case "rejected":
                return t("app.reject").toLowerCase()
        }
    }

    const handleActionButtons = (decision: "" | "rejected" | "accepted", requestID: string, requestUser: string) =>
    {
        setDecision(decision);
        setRequestID(requestID);
        setRequestUser(requestUser)
    }

    return (
        <div className={styles.container}>
            {!isLoading ?
                <table>
                    <thead>
                    <tr>
                        <th>{t("app.user")}</th>
                        <th>{t("app.message")}</th>
                        <th>{t("app.action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {deviceRequest.filter(request => request.state === "pending").length ? deviceRequest.filter(request => request.state === "pending").map(request =>
                        <tr key={request._id}>
                            <td data-label={`${t("app.user")}: `}>{extractFullNameFromEmail(request.userEmail)}</td>
                            <td data-label={`${t("app.message")}: `} onClick={() =>
                                setReadMoreMessage(request.userMessage)
                            } className={`${styles.message} ${styles.read_more}`}>
                                {request.userMessage}</td>
                            <td className={styles.action_controls}>
                                <Button btnType="danger" onClick={() => handleActionButtons("rejected", request._id, request.userEmail)}>{t("app.reject")}</Button>
                                <Button onClick={() => handleActionButtons("accepted", request._id, request.userEmail)}>{t("app.accept")}</Button>
                            </td>
                        </tr>
                    ) : <tr><td>{t("requests.noRequestFound")}</td></tr>}
                    </tbody>
                </table>
                : <div className={styles.loader}><Loader /></div>}

            {readMoreMessage ?
                <div className={absoluteStyles.window_container} onClick={() => setReadMoreMessage(null)} onTouchEnd={() => setReadMoreMessage(null)}>
                    <div className={absoluteStyles.window_container_content} style={{padding: 0}} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                        <div className={absoluteStyles.info_content}>
                            <div className={styles.read_more_container}>
                                <span>{readMoreMessage}</span>
                                <Button btnType="danger" onClick={() => setReadMoreMessage(null)}>{t("app.close")}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            : null}

            {decision.length ?
                <ConfirmActionScreen
                    close={handleCloseDecision}
                    confirm={handleDecision}
                    message={
                        <div className="request_container">
                            <label>
                                <span>{t("requests.leaveAMessage")} ({t("app.optional").toLowerCase()})</span>
                                <textarea value={adminMessage} onChange={e => setAdminMessage(e.target.value)} />
                            </label>
                        </div>
                    }
                    title={
                    <>
                        {t("requests.deleteMessage1")} <span style={{color: decision === "rejected" ? "var(--error)" : "var(--success)", padding: 0}}>{handleConfirmDecisionTitle()}</span> <a href={`mailto:${requestUser}`}>{extractFullNameFromEmail(requestUser)}</a> request?
                    </>
                    } />
            : null}

        </div>
    );
}

export default DeviceRequests;
