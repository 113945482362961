import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import {createDeviceRequest} from "../helpers/APIEnpoints";
import {device} from '../helpers/types';
import Context from "../helpers/Context";
import {useTranslation} from "react-i18next";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import styles from "./css/DeviceRequestModal.module.css";
import Button from "./Button";
import {inputValidation} from "../helpers/helperFunctions";

type defaultProps =
{
    device: device;
    handleClose: () => void;
    isDeviceArray?: boolean;
    setIsRequestMade?: Dispatch<SetStateAction<boolean>>;
}

type deviceArray =
{
    isDeviceArray: true;
    devices: device[];
    setDevices: Dispatch<SetStateAction<device[]>>;
} |
{
    isDeviceArray?: false;
    devices?: never;
    setDevices?: never;
}

type props = defaultProps & deviceArray;

const DeviceRequestModal: FC<props> = ({device, handleClose, isDeviceArray = false, devices, setDevices, setIsRequestMade}) =>
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const [userRequestMessage, setUserRequestMessage] = useState<string>("");
    const isAuthenticated = useIsAuthenticated();

    const [fullName, setFullName] = useState<{firstName: string, lastName: string}>({firstName: "", lastName: ""});

    const {accounts, instance} = useMsal();

    const handleRequestDevice = async () =>
    {
        if(isAuthenticated || inputValidation([{value: fullName.firstName, label: t("app.fullName")}, {value: fullName.lastName, label: t("app.lastName")}], notificationDispatch))
        {
            const request = await createDeviceRequest({deviceID: device._id, userMessage: userRequestMessage, fullName: fullName}, notificationDispatch, {accounts: accounts, instance: instance});

            if(request)
            {
                setUserRequestMessage("");

                if(isDeviceArray)
                {
                    const index = devices!.findIndex(currDevice => currDevice._id === device._id);
                    setDevices!(current =>
                    {
                        current[index] = request.device;
                        return current;
                    })
                }

                if(setIsRequestMade) setIsRequestMade(true);
                handleClose();
            }
        }
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.container}>
                        <span className={styles.header}>{`${t("requests.title")} ${device.model}?`}</span>

                        <div className={styles.request_container}>
                            {device.state !== "free" && <span className={styles.leasingEndDate}>{t("requests.leasingEndsIn")} {(device.leasingEndDate && device.leasingEndDate.length) ? device.leasingEndDate : t("app.unknown").toLowerCase()}</span>}
                            {!isAuthenticated &&
                                <>
                                    <label>
                                        <span>{t("app.firstName")}*</span>
                                        <input type="text" value={fullName.firstName} onChange={e => setFullName(current =>
                                            ({
                                                ...current, firstName: e.target.value
                                            }))} />
                                    </label>
                                    <label>
                                        <span>{t("app.lastName")}*</span>
                                        <input type="text" value={fullName.lastName} onChange={e => setFullName(current =>
                                            ({
                                                ...current, lastName: e.target.value
                                            }))} />
                                    </label>
                                </>
                            }
                            <label>
                                <span>{t("requests.leaveAMessage")} ({t("app.optional").toLowerCase()})</span>
                                <textarea value={userRequestMessage} onChange={e => setUserRequestMessage(e.target.value)} />
                            </label>
                        </div>

                        <div className={styles.controls}>
                            <Button btnType="danger" onClick={handleClose}>{t("app.cancel")}</Button>
                            <Button onClick={handleRequestDevice}>{t("app.confirm")}</Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default DeviceRequestModal;
