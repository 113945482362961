import React, {useContext, useEffect, useState} from 'react';
import styles from "./css/Devices.module.css"
import Button from "../../utilities/Button";
import Context from "../../helpers/Context";
import {device} from "../../helpers/types";
import {deleteDeviceByID, getAllDevicesAdmin} from "../../helpers/APIEnpoints";
import DeviceCard from "../../utilities/DeviceCard";
import ManageDevice from "./ManageDevice";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import Search from "../../utilities/Search";
import {PulseLoader} from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";

const Devices = ({}) =>
{
    const {t} = useTranslation();

    const {accounts, instance} = useMsal();

    const [isAddManage, setIsAddManage] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);

    const [device, setDevice] = useState<device | null>(null);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const {notificationDispatch} = useContext(Context);

    const [devices, setDevices] = useState<device[]>([]);
    const filteredDevices = devices.filter(device => searchQuery.length ?
        String(device.toriID).includes(searchQuery.toLowerCase()) || device.model.toLowerCase().includes(searchQuery.toLowerCase()) : device);

    const [isDeviceDeleting, setIsDeviceDeleting] = useState<boolean>(false);

    const [secondSlice, setSecondSlice] = useState<number>(20);

    useEffect(() =>
    {
        if(isDelete || isAddManage)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[isDelete, isAddManage]);

    useEffect(() =>
    {
        (async () =>
        {
            const devices = await getAllDevicesAdmin(notificationDispatch, {accounts: accounts, instance: instance});
            if(devices) setDevices(devices);
        })();
    }, []);

    const handleManage = (device: device) =>
    {
        setDevice(device);
        setIsAddManage(true);
    }

    const onDelete = (device: device) =>
    {
        setDevice(device);
        setIsDelete(true);
    }

    const handleDeviceDelete = async () =>
    {
        setIsDeviceDeleting(true);
        const deletedDevice = await deleteDeviceByID(device!._id, notificationDispatch, {accounts: accounts, instance: instance});
        if(deletedDevice)
        {
            setDevices(current => current.filter(currDevice => currDevice._id !== device!._id));
            handleDeleteClose();
        }

        setIsDeviceDeleting(false);
    }

    const handleDeleteClose = () =>
    {
        setDevice(null);
        setIsDelete(false);
    }

    return (
        <div className={styles.container}>
            <div className={styles.top_controls}>
                <div className={styles.search_container}><Search placeholder={t("app.search")} onChange={setSearchQuery}/></div>
                <Button onClick={() => setIsAddManage(true)}>{t("app.add")}</Button>
            </div>

            <InfiniteScroll
                dataLength={secondSlice <= filteredDevices.length ? secondSlice : filteredDevices.length}
                next={() => setSecondSlice(current => current+20)}
                hasMore={secondSlice < filteredDevices.length}
                className={styles.devices_list_content}
                loader={<div className={styles.loader}><PulseLoader color="var(--btnColor)" size={15} /></div>}>

                    <div className={styles.devices_list}>
                        {filteredDevices.slice(0, secondSlice)
                            .map(device => <DeviceCard key={device._id} device={device} isControlPanel={true}
                                                       onDelete={(device, e) => {e.stopPropagation();onDelete(device)}} onEdit={handleManage} />)}
                    </div>
            </InfiniteScroll>

            {isAddManage &&
                <ManageDevice device={device}
                              setDevice={setDevice}
                              devices={devices}
                              setDevices={setDevices}
                              setIsAddManage={setIsAddManage} />
            }

            {isDelete &&
                <ConfirmActionScreen close={handleDeleteClose} confirm={handleDeviceDelete} isDisableButtons={isDeviceDeleting}
                                     message=""
                                     title={`${t("controlPanel.delete")} ${device?.model} (${device?._id.slice(10, 20)})?`} />
            }
        </div>
    );
}

export default Devices;
