import {loginRequest} from "../authConfig";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {Dispatch} from "react";
import {device, deviceCompany, deviceImage, deviceRequests, deviceType, notificationDispatch} from "./types";
import {t} from "i18next";
import {v4} from "uuid";
import {handleDecisionTranslate} from "./helperFunctions";
import {Buffer} from "buffer";

export const RequestAccessToken = async (accounts: AccountInfo[], instance: IPublicClientApplication, notificationDispatch: Dispatch<notificationDispatch>): Promise<string> =>
{
    if(accounts.length)
    {
        const account = instance.getActiveAccount();

        const request =
            {
                ...loginRequest,
                account: account !== null ? account : undefined
            };

        return instance.acquireTokenSilent(request).then(response =>
        {
/*            console.log(response)*/
            return response.accessToken;
        }).catch(e =>
        {
            console.log("Error token silent")
            console.log(e)
            instance.acquireTokenRedirect(request).catch(e =>
            {
                console.log("Error token redirect")
                console.log(e)
            })
            return "";
        })

    } else return "";
}

export const getAllDevices = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<device[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/device`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getAllDevicesAdmin = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<device[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/device/admin/all`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getDeviceImagesByID = async (deviceID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceImage[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/image/${deviceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const deleteDeviceImages = async (imagesIDs: string[], deviceID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<void | boolean> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/image/`,
            {
                method: 'DELETE',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "imageIDs": imagesIDs,
                    "deviceID": deviceID
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`device.imageDeleted2`)} ${imagesIDs.map(id => id).join(", ")} ${t(`backEnd.${data.loc}`)}`
                        }
                })

                return true;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getDeviceByID = async (deviceID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<device  | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/device/${deviceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const addDevice = async (newDevice: device, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<device  | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {typeID, model, description, leasingEndDate, specs, images, state, companyID} = newDevice;

        const body = new FormData();
        body.append('typeID', typeID._id);
        body.append('model', model.trim().replace(/\s\s+/g, ' '));
        body.append('description', description.trim().replace(/\s\s+/g, ' '));
        body.append('specs', JSON.stringify(specs));
        body.append('leasingEndDate', leasingEndDate.toString());
        body.append('state', state);
        body.append('companyID', (companyID as deviceCompany)._id);
        (images as File[]).map(image =>  body.append('images', image));

        return await fetch(`${process.env.REACT_APP_API_URL}/device`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`},
                body: body
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.device;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return ;
    }
}

export const modifyDeviceByID = async (device: device, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<device | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {_id, typeID, model, description, leasingEndDate, images, specs, state, companyID} = device;

        const body = new FormData();
        body.append('typeID', typeID._id);
        body.append('model', model.trim().replace(/\s\s+/g, ' '));
        body.append('description', description.trim().replace(/\s\s+/g, ' '));
        body.append('specs', JSON.stringify(specs ? specs : {}));
        body.append('leasingEndDate', leasingEndDate);
        body.append('state', state);
        body.append('companyID', (companyID as deviceCompany)._id);
        (images as File[]).map(image =>  body.append('images', image));

        return await fetch(`${process.env.REACT_APP_API_URL}/device/${_id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`},
                body: body
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.device;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return ;
    }
}

export const deleteDeviceByID = async (deviceID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<void | boolean> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/device/${deviceID}`,
            {
                method: 'DELETE',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`app.device`)} ${deviceID} ${t(`backEnd.${data.loc}`)}`
                        }
                })

                return true;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getAllDeviceTypes = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceType[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/type`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const addType = async (newType: deviceType, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceType | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {name, typeSpecs} = newType;

        return await fetch(`${process.env.REACT_APP_API_URL}/type`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' '),
                    "typeSpecs": typeSpecs
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.type;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const modifyTypeByID = async (type: deviceType, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceType | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {_id, name, typeSpecs} = type;

        return await fetch(`${process.env.REACT_APP_API_URL}/type/${_id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' '),
                    "typeSpecs": typeSpecs,
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.type;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const deleteTypeByID = async (typeID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<void | boolean> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/type/${typeID}`,
            {
                method: 'DELETE',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`device.type`)} ${typeID} ${t(`backEnd.${data.loc}`)}`
                        }
                })

                return true;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getAllRequests= async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceRequests[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/request`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getDeviceRequestsByID = async (deviceID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceRequests[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/request/${deviceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const createDeviceRequest = async (request: {deviceID: string, userMessage: string, fullName: {firstName: string, lastName: string}}, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<{request: deviceRequests, device: device} | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {deviceID, userMessage, fullName} = request;

        return await fetch(`${process.env.REACT_APP_API_URL}/request`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "deviceID": deviceID,
                    "userMessage": userMessage.trim().replace(/\s\s+/g, ' '),
                    "fullName": fullName
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const manageRequestByID = async (manageRequest: {_id: string, decision: string, adminMessage: string}, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<{ request: deviceRequests, device: device } | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {_id, adminMessage, decision} = manageRequest;

        return await fetch(`${process.env.REACT_APP_API_URL}/request/${_id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "decision": decision,
                    "adminMessage": adminMessage.trim().replace(/\s\s+/g, ' ')
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`backEnd.${data.loc}`)} ${handleDecisionTranslate(data.request.state)}!`
                        }
                })

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const manageUserRequestByID = async (manageRequest: {_id: string, userMessage: string}, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<{request: deviceRequests} | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {_id, userMessage} = manageRequest;

        return await fetch(`${process.env.REACT_APP_API_URL}/request/user/${_id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "userMessage": userMessage.trim().replace(/\s\s+/g, ' ')
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}


export const deleteRequestByID = async (requestID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<void | boolean> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/request/${requestID}`,
            {
                method: 'DELETE',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`device.request`)} ${requestID} ${t(`backEnd.${data.loc}`)}`
                        }
                })

                return true;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getUserRequests = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceRequests[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/request/user/all`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getToriPassword = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<{_id: string, password: string} | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/toriPassword`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const updateToriPassword = async (toriPass: {_id: string, password: string}, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<{_id: string, password: string} | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {_id, password} = toriPass;

        return await fetch(`${process.env.REACT_APP_API_URL}/toriPassword/`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "password": password.trim().replace(/\s\s+/g, ' '),
                    "id": _id
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.pass;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const verifyToriPass = async (password: string, notificationDispatch: Dispatch<notificationDispatch>): Promise<boolean | void> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/toriPassword/verify`,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "password": Buffer.from(password.trim().replace(/\s\s+/g, ' ')).toString("base64")
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return true;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const getAllDeviceCompanies = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceCompany[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/deviceCompany`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const addDeviceCompany = async (newDeviceCompany: deviceCompany, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceCompany | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {name} = newDeviceCompany;

        return await fetch(`${process.env.REACT_APP_API_URL}/deviceCompany`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' ')
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.company;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const modifyDeviceCompanyByID = async (deviceCompany: deviceCompany, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<deviceCompany | void> =>
{
    try
    {
        const {accounts, instance} = token;

        const {_id, name} = deviceCompany;

        return await fetch(`${process.env.REACT_APP_API_URL}/deviceCompany/${_id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' '),
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.company;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}

export const deleteDeviceCompanyByID = async (deviceCompanyID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<void | boolean> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/deviceCompany/${deviceCompanyID}`,
            {
                method: 'DELETE',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance, notificationDispatch)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error) throw (data.loc ? t(`backEnd.${data.loc}`) : data.error)

                if(typeof data === "string") throw (data);

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`app.device`)} ${t(`device.company`)} ${deviceCompanyID} ${t(`backEnd.${data.loc}`)}`
                        }
                })

                return true;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return;
    }
}
