import React, {useContext, useEffect, useState} from 'react';
import styles from './css/ToriPassword.module.css';
import {getToriPassword, updateToriPassword} from "../../helpers/APIEnpoints";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";
import {Buffer} from "buffer";
import {useMsal} from "@azure/msal-react";

function ToriPassword()
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const [toriPass, setToriPass] = useState<{_id: string, password: string}>({_id: "", password: ""});

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        (async () =>
        {
            const pass = await getToriPassword(notificationDispatch, {accounts: accounts, instance: instance});

            if(pass && pass._id.length) setToriPass({_id: pass._id, password: Buffer.from(pass.password, "base64").toString("utf-8")});
        })();
    }, []);

    const handleToriPassUpdate = async () =>
    {
        const updatedPass = await updateToriPassword(toriPass, notificationDispatch, {accounts: accounts, instance: instance});

        if(updatedPass) setToriPass({_id: updatedPass._id, password: Buffer.from(updatedPass.password, "base64").toString("utf-8")});
    }

    return (
        <div className={styles.container}>
            <label>
                {t("controlPanel.toriPassword")}
                <input type="text" value={toriPass.password} onChange={e => setToriPass(current => ({...current,
                password: e.target.value
                }))}/>
            </label>
            <Button onClick={handleToriPassUpdate}>{t("controlPanel.updatePass")}</Button>
        </div>
    );
}

export default ToriPassword;
