import React from 'react';

function BuutiLogo()
{
    return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="1024.000000pt" height="258.000000pt" viewBox="0 0 1024.000000 258.000000"
                preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,258.000000) scale(0.100000,-0.100000)"
           stroke="none">
            <path d="M1110 1845 l0 -735 180 0 180 0 0 735 0 735 -180 0 -180 0 0 -735z"/>
            <path d="M8190 1810 l0 -410 -55 0 -55 0 0 -185 0 -185 55 0 55 0 0 -348 c0
-386 3 -411 63 -499 40 -57 115 -117 177 -139 30 -11 90 -18 178 -21 l132 -5
0 186 0 186 -90 0 -90 0 0 320 0 320 90 0 90 0 -2 183 -3 182 -87 3 -88 3 -2
407 -3 407 -182 3 -183 2 0 -410z"/>
            <path d="M4 2197 c-2 -7 -3 -89 -2 -182 l3 -170 367 -3 368 -2 0 185 0 185
-365 0 c-289 0 -367 -3 -371 -13z"/>
            <path d="M1840 2025 l0 -185 185 0 185 0 0 -550 0 -550 180 0 180 0 0 735 0
735 -365 0 -365 0 0 -185z"/>
            <path d="M3140 2030 l0 -180 85 0 85 0 0 -915 0 -915 443 0 c508 1 554 5 682
69 190 95 315 260 351 464 46 256 -77 525 -304 662 -1 1 9 22 22 46 14 24 37
80 52 124 33 98 37 242 11 345 -59 229 -244 411 -472 465 -47 12 -156 15 -507
15 l-448 0 0 -180z m887 -195 c65 -19 139 -85 169 -150 62 -132 0 -295 -136
-359 -39 -18 -67 -21 -212 -24 l-168 -4 0 276 0 276 148 0 c103 0 164 -5 199
-15z m251 -931 c202 -101 201 -378 -2 -487 -39 -21 -54 -22 -318 -25 l-278 -3
0 270 0 271 273 0 273 0 52 -26z"/>
            <path d="M9090 1805 l0 -405 -55 0 -55 0 0 -185 0 -185 55 0 55 0 0 -344 c0
-316 2 -349 20 -407 35 -110 108 -188 220 -234 33 -13 80 -20 178 -23 l132 -4
0 186 0 186 -90 0 -90 0 0 320 0 320 90 0 90 0 0 185 0 185 -90 0 -90 0 0 405
0 405 -185 0 -185 0 0 -405z"/>
            <path d="M9870 2015 l0 -195 185 0 185 0 0 195 0 195 -185 0 -185 0 0 -195z"/>
            <path d="M2 738 l3 -733 183 -3 182 -2 0 735 0 735 -185 0 -185 0 2 -732z"/>
            <path d="M5072 943 c4 -449 4 -459 27 -520 24 -66 80 -158 128 -209 152 -165
413 -235 617 -166 l56 19 0 -24 0 -23 185 0 185 0 -2 688 -3 687 -180 0 -180
0 -5 -425 c-6 -473 -3 -455 -76 -520 -50 -44 -93 -60 -157 -60 -67 0 -145 37
-182 87 -52 68 -55 93 -55 525 l0 398 -180 0 -181 0 3 -457z"/>
            <path d="M6580 973 c0 -342 3 -441 15 -491 51 -216 241 -401 460 -447 112 -23
162 -20 342 22 16 4 21 1 20 -14 -2 -17 10 -18 181 -21 l182 -2 0 690 0 690
-179 0 -180 0 -3 -417 c-3 -407 -4 -419 -25 -458 -46 -86 -123 -135 -213 -135
-90 0 -167 49 -213 135 -21 39 -22 51 -25 458 l-3 417 -180 0 -179 0 0 -427z"/>
            <path d="M9870 705 l0 -685 185 0 185 0 0 685 0 685 -185 0 -185 0 0 -685z"/>
            <path d="M740 185 l0 -185 915 0 915 0 0 185 0 185 -915 0 -915 0 0 -185z"/>
        </g>
    </svg>
}

export default BuutiLogo;
