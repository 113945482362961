import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import styles from './css/FirstTimeBanner.module.css';
import {useTranslation} from "react-i18next";
import Button from "./Button";
import {handleDeviceStateTranslation, upperCaseFirstLetter} from "../helpers/helperFunctions";

interface IProps
{
    setIsShown: Dispatch<SetStateAction<boolean>>;
}

const FirstTimeBanner: FC<IProps> = ({setIsShown}) =>
{
    const {t} = useTranslation();

    useEffect(() =>
    {
        if(!localStorage.getItem("bannerHidden")) localStorage.setItem("bannerHidden", "0");
    }, []);

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const {checked} = e.target;

        checked ? localStorage.setItem("bannerHidden", "1") : localStorage.setItem("bannerHidden", "0");
    }

    const handleClose = () =>
    {
        if(localStorage.getItem("bannerHidden") === "0") sessionStorage.setItem("hideBannerSession", "1");
        setIsShown(false);
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.container}>
                        <h1>{t("backEnd.welcomeTori")}</h1>

                        <div className={styles.info_container}>
                            <div className={styles.descriptions}>
                                <p>
                                    <span className={`${styles.state} ${styles.free}`}>{upperCaseFirstLetter(handleDeviceStateTranslation("free"))}</span>
                                    {t("device.stateDescriptionFree")}
                                </p>
                                <p>
                                    <span className={`${styles.state} ${styles.available}`}>{upperCaseFirstLetter(handleDeviceStateTranslation("available"))}</span>
                                    {t("device.stateDescriptionAvailable")}
                                </p>

                                <p className={styles.warning}>{t("device.stateDescriptionGeneral")}
                                    <span className={`${styles.state} ${styles.available}`}>{upperCaseFirstLetter(handleDeviceStateTranslation("available"))}</span>
                                    {t("device.stateDescriptionGeneral2")}
                                </p>
                            </div>
                        </div>

                        <div className={styles.controls}>
                            <label>
                                <input type="checkbox" onChange={e => handleCheckbox(e)}
                                       defaultChecked={localStorage.getItem("bannerHidden") === "1"} />
                                {t("app.hideBanner")}
                            </label>
                            <Button btnType="danger" onClick={() => handleClose()}>{t("app.close")}</Button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default FirstTimeBanner;
