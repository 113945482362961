import {
    FaChevronLeft,
    FaChevronRight,
    FaEdit, FaExternalLinkAlt,
    FaFilter,
    FaRegClock, FaRegImage, FaStoreAlt,
    FaTimes,
    FaTrash,
    FaUserCircle
} from "react-icons/fa";
import {BsCheckLg, BsDiagram2, BsExclamationLg, BsFillExclamationTriangleFill, BsTelephone} from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import {RiArrowDownSLine, RiArrowUpSLine, RiSearchLine} from "react-icons/ri";
import {FaXmark} from "react-icons/fa6";
import {MdSpaceDashboard} from "react-icons/md";
import NetumLogo from "../assets/netum-logo";
import BuutiLogo from "../assets/buutti-logo";

export const Icons =
{
    edit: <FaEdit />,
    delete: <FaTrash />,
    close: <FaTimes />,
    clock: <FaRegClock />,
    error: <BsFillExclamationTriangleFill />,
    success: <BsCheckLg />,
    warning: <BsExclamationLg />,
    arrowLeft: <FaChevronLeft />,
    arrowRight: <FaChevronRight />,
    hamburgerMenu: <GiHamburgerMenu />,
    user: <FaUserCircle />,
    descArrow: <TiArrowSortedDown />,
    ascArrow: <TiArrowSortedUp />,
    unsorted: <TiArrowUnsorted />,
    arrowDown: <RiArrowDownSLine />,
    arrowUp: <RiArrowUpSLine />,
    search: <RiSearchLine />,
    crossDelete: <FaXmark />,
    phone: <BsTelephone />,
    hierarchy: <BsDiagram2 />,
    filter: <FaFilter />,
    link: <FaExternalLinkAlt />,
    store: <FaStoreAlt />,
    dashboard: <MdSpaceDashboard />,
    image: <FaRegImage />,
    netum: <NetumLogo />,
    buutti: <BuutiLogo />
}
