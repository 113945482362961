import React, {ChangeEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {device, deviceType, typeSpecs} from "../../helpers/types";
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import _ from 'lodash';
import styles from './css/AddModifyType.module.css'
import Button from "../../utilities/Button";
import {addType, modifyTypeByID} from "../../helpers/APIEnpoints";
import Context from "../../helpers/Context";
import {v4} from "uuid";
import {useTranslation} from "react-i18next";
import {inputValidation} from "../../helpers/helperFunctions";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useMsal} from "@azure/msal-react";

type defaultProps =
{
    setIsAddModifyType: Dispatch<SetStateAction<boolean>>

    type: deviceType | null;
    setType: Dispatch<SetStateAction<deviceType | null>>;

    types: deviceType[];
    setTypes: Dispatch<SetStateAction<deviceType[]>>;

    isAddModifyDevice?: boolean;
}

type addModifyDeviceProps =
{
    isAddModifyDevice: true;
    setNewModifyDevice: Dispatch<SetStateAction<device>>;
    setCurrentTypeSpecs: Dispatch<SetStateAction<{_id: string, name: string, required: boolean}[]>>;
} |
{
    isAddModifyDevice?: false;
    setNewModifyDevice?: never;
    setCurrentTypeSpecs?: never;
}

type props = defaultProps & addModifyDeviceProps;

const AddModifyType: FC<props> = ({setIsAddModifyType, types, type, setTypes, setType, isAddModifyDevice= false, setNewModifyDevice, setCurrentTypeSpecs}) =>
{
    const {notificationDispatch} = useContext(Context);

    const [newModifyType, setNewModifyType] = useState<deviceType>({_id: "", name: "", typeSpecs: []});

    const [specsArr, setSpecsArr] = useState<typeSpecs[]>([]);

    const {t} = useTranslation();

    const [isChangesSaved, setIsChangesSaved] = useState<boolean>(true);

    const [isShowChangesNotSaved, setIsShowChangesNotSaved] = useState<boolean>(false);

    const [initialTypeState, setInitialTypeState] = useState<deviceType>({_id: "", name: "", typeSpecs: []});

    const [selectorOptionsSpecIDs, setSelectorOptionsSpecIDs] = useState<string[]>([]);

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        if(type !== null)
        {
            setNewModifyType(_.clone(type));
            setSpecsArr(_.cloneDeep(type.typeSpecs));
        }
    }, []);

    useEffect(() =>
    {
        setIsChangesSaved((((type && _.isEqual(type, newModifyType)) || _.isEqual(newModifyType, initialTypeState)) && _.isEqual(newModifyType.typeSpecs, specsArr) ));
    }, [newModifyType, specsArr]);

    const handleClose = () =>
    {
        setIsAddModifyType(false);
        setNewModifyType({_id: "", name: "", typeSpecs: []});
        setType(null);
    }

    const handleSpecsArr = (specID: string | null = null) =>
    {
        if(specID === null)
        {
            setSpecsArr(current => [...current, {_id: v4(), name: "", required: false, isSelector: false, selectorArray: []}]);
            return;
        }

        setSpecsArr(current => current.filter(spec => spec._id !== specID));
    }

    const handleSpecChange = (e: ChangeEvent<HTMLInputElement>, specID: string) =>
    {
        let { name, value, checked } = e.target;

        const index = specsArr.findIndex(spec => spec._id === specID);

        setSpecsArr(current =>
        {
            if(name === "name") current[index].name = value;
            if(name === "required") current[index].required = checked;
            if(name === "isSelector")
            {
                current[index].isSelector = checked;
                if(checked)
                {
                    current[index].selectorArray = [{_id: v4(), option: ""}, {_id: v4(), option: ""}];
                    if(!selectorOptionsSpecIDs.includes(specID)) setSelectorOptionsSpecIDs(current => [...current, specID]);
                } else current[index].selectorArray = [];
            }

            return [...current];
        });
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    {
        let { name, value } = e.target;

        setNewModifyType(prev => ({...prev, [name]: value}));
    };

    const handleTypeAddModify = async () =>
    {
        const specOptions = specsArr.map(spec => spec.selectorArray.map(specOption => {return {value: specOption.option, label: t("controlPanel.selectorOption")}})).flat(1);

        if(inputValidation([{value: newModifyType.name, label: t("controlPanel.typeName")}, ...specsArr.map(spec => {return {value: spec.name, label: t("controlPanel.specName")}})
        , ...specOptions], notificationDispatch))
        {
            if(type)
            {
                const modifiedType = await modifyTypeByID({...newModifyType, typeSpecs: specsArr}, notificationDispatch,{accounts: accounts, instance: instance});

                if(modifiedType)
                {
                    const index = types.findIndex(type => type._id === modifiedType._id);
                    setTypes(current =>
                    {
                        current[index] = modifiedType;
                        return [...current];
                    });

                    handleClose();
                }
            } else
            {
                const addedType = await addType({...newModifyType, typeSpecs: specsArr}, notificationDispatch, {accounts: accounts, instance: instance});
                if(addedType)
                {
                    setTypes(current => [...current, addedType]);

                    if(isAddModifyDevice)
                    {
                        setNewModifyDevice!(current => {
                            current.typeID = {_id: addedType._id, name: addedType.name};

                            return current
                        });

                        setCurrentTypeSpecs!(addedType.typeSpecs);
                    }

                    handleClose();
                }
            }
            setIsChangesSaved(true);
        }
    }

    const handleSelectorOptionArray = (specID: string, optionID: string | null = null) =>
    {
        const index = specsArr.findIndex(spec => spec._id === specID);

        if(optionID === null)
        {
            setSpecsArr(current =>
            {
                current[index].selectorArray = [...current[index].selectorArray, {_id: v4(), option: ""}]

                return [...current];
            });

            if(!selectorOptionsSpecIDs.includes(specID)) setSelectorOptionsSpecIDs(current => [...current, specID]);

            return;
        }

        setSpecsArr(current =>
        {
            current[index].selectorArray = current[index].selectorArray.filter(option => option._id !== optionID);

            return [...current];
        });

        if(!specsArr[index].selectorArray.length)
        {
            setSpecsArr(current =>
            {
                current[index].isSelector = false;

                return [...current];
            });

            handleShowHideSelectorOptions(specID);
        }

        return;
    }

    const handleSelectorOptionChange = (e: ChangeEvent<HTMLInputElement>, specID: string, optionID: string) =>
    {
        let { value } = e.target;

        const specIndex = specsArr.findIndex(spec => spec._id === specID);
        const optionIndex = specsArr[specIndex].selectorArray.findIndex(option => option._id === optionID);

        setSpecsArr(current =>
        {
            current[specIndex].selectorArray[optionIndex].option = value;

            return [...current];
        })
    }

    const handleShowHideSelectorOptions = (specID: string) =>
    {
        if(!selectorOptionsSpecIDs.includes(specID))
        {
            setSelectorOptionsSpecIDs(prev => [...prev, specID]);
        } else
        {
            setSelectorOptionsSpecIDs(current => current.filter(id => id !== specID));
        }
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)} onTouchEnd={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)}>
            <div className={absoluteStyles.window_container_content} style={{padding: 0}} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.container}>
                        <label>
                            {t("controlPanel.typeName")}*
                            <input type="text" name="name" defaultValue={newModifyType.name} onChange={e => handleChange(e)} />
                        </label>
                        <div className={styles.specs_list}>
                            <div className={styles.list_top}>
                                <span>{t("device.specs")}</span>
                                <Button onClick={() => handleSpecsArr()}>{t("controlPanel.addNewSpec")}</Button>
                            </div>

                            <table>
                                <thead>
                                <tr>
                                    <th>{`${t("controlPanel.specName")}*`}</th>
                                    <th>{t("controlPanel.required")}</th>
                                    <th>{t("controlPanel.selector")}</th>
                                    <th>{t("app.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {specsArr.length ? specsArr.map(spec =>
                                    <React.Fragment key={spec._id} >
                                        <tr>
                                            <td data-label={t("controlPanel.specName")} className={styles.spec_name}><input type="text" name="name" placeholder="Spec name*" defaultValue={spec.name} onChange={e => handleSpecChange(e, spec._id)} /></td>
                                            <td data-label={`${t("controlPanel.required")}: `}><input type="checkbox" name="required" defaultChecked={spec.required} onChange={e => handleSpecChange(e, spec._id)} /></td>
                                            <td data-label={`${t("controlPanel.selector")}: `}><input type="checkbox" name="isSelector" checked={spec.isSelector} onChange={e => handleSpecChange(e, spec._id)} /></td>
                                            <td className={styles.table_action}>
                                                <Button btnType="danger" btnMini={true} iconType="delete" onClick={() => handleSpecsArr(spec._id)}>{t("app.delete")}</Button>
                                                {spec.isSelector ? <Button btnMini={true} onClick={() => handleSelectorOptionArray(spec._id)}>{t("app.addOption")}</Button> : null}
                                            </td>
                                        </tr>
                                        {selectorOptionsSpecIDs.includes(spec._id) && spec.isSelector ? spec.selectorArray.map(optionSpec =>
                                            <tr key={optionSpec._id} className={styles.array_spec_container}>
                                                <td><input type="text" defaultValue={optionSpec.option} placeholder={`${t("controlPanel.selectorOption")}*`} onChange={e => handleSelectorOptionChange(e, spec._id, optionSpec._id)} /></td>
                                                <td className={`${styles.table_action} ${styles.option_controls}`}>
                                                    <Button btnType="danger" btnMini={true} iconType="delete" onClick={() => handleSelectorOptionArray(spec._id, optionSpec._id)}>{t("app.delete")}</Button>
                                                </td>
                                            </tr>
                                            ): null}
                                        {spec.isSelector ? <tr><td className={styles.selectorShowHide}><button onClick={() => handleShowHideSelectorOptions(spec._id)}>-----{selectorOptionsSpecIDs.includes(spec._id) ? t("app.hide") : t("app.show")}-----</button></td></tr> : null}
                                    </React.Fragment>
                                    ): <tr><td>{t("controlPanel.noSpecsFound")}</td></tr>}
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.controls}>
                            <Button btnType="danger" onClick={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)}>{t("app.cancel")}</Button>
                            <Button onClick={handleTypeAddModify}>{`${t("app.save")}${isChangesSaved ? "" : "*"}`}</Button>
                        </div>

                        {isShowChangesNotSaved &&
                            <ConfirmActionScreen
                                close={() => setIsShowChangesNotSaved(false)}
                                confirm={handleClose}
                                title={t("notSavedModal.title")}
                                message={
                                    <div className={styles.not_saved_message}>
                                        <span>{t("notSavedModal.warningMessage")}</span>
                                        <span style={{color: "var(--error)"}}><strong>{t("notSavedModal.warningMessageSubtitle")}</strong></span>
                                    </div>
                                }/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddModifyType;
