import {createContext, Dispatch, FC, ReactNode, SetStateAction, useReducer, useState} from "react";
import {notification, photo} from "./types";

interface IProps
{
    children: ReactNode;
}

interface IContext
{
    groupsID: string[];
    setGroupsID: Dispatch<SetStateAction<string[]>>;

    notificationState: notification[];
    notificationDispatch: any;

    hideNotificationPrompt: boolean;
    setHideNotificationPrompt: Dispatch<SetStateAction<boolean>>;

    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;

    devicePhotos: photo[];
    setDevicePhotos: Dispatch<SetStateAction<photo[]>>;
}

const Context = createContext<IContext>({
    groupsID: [],
    hideNotificationPrompt: false,
    notificationDispatch: undefined,
    notificationState: [],
    isLoading: false,
    setIsLoading(value: ((prevState: boolean) => boolean) | boolean): void {
    },
    setGroupsID(value: ((prevState: string[]) => string[]) | string[]): void {
    },
    setHideNotificationPrompt(value: ((prevState: boolean) => boolean) | boolean): void {
    }, devicePhotos: [],
    setDevicePhotos(value: ((prevState: photo[]) => photo[]) | photo[]): void {
    }
});

export const ContextProvider: FC<IProps> = ({children}) =>
{
    const [groupsID, setGroupsID] = useState<string[]>([]);
    const [notificationState, notificationDispatch] = useReducer((state: notification[], action: any) =>
    {
        switch (action.type)
        {
            case "ADD_NOTIFICATION":
                return [...state, {...action.payload}];
            case "REMOVE_NOTIFICATION":
                return state.filter(item => item.id !== action.id);
            default:
                return state;
        }
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideNotificationPrompt, setHideNotificationPrompt] = useState<boolean>(true);

    const [devicePhotos, setDevicePhotos] = useState<photo[]>([]);

    return(
        <Context.Provider value={{groupsID, setGroupsID, notificationState, notificationDispatch, devicePhotos, setDevicePhotos,
            isLoading, setIsLoading, hideNotificationPrompt, setHideNotificationPrompt}}>
            {children}
        </Context.Provider>
    )
}

export default Context;
