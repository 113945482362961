import React, {ChangeEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {device, deviceCompany, deviceImage, deviceType} from "../../helpers/types";
import styles from './css/AddModifyDevice.module.css';
import Context from "../../helpers/Context";
import {
    getAllDeviceCompanies,
    getAllDeviceTypes,
    getDeviceImagesByID,
} from "../../helpers/APIEnpoints";
import _ from 'lodash';
import {useDropzone} from "react-dropzone";
import {v4} from "uuid";
import {
    blobToBase64,
    getDevicePhotos, getPhotoIndex,
    handleDeviceStateTranslation,
    upperCaseFirstLetter
} from "../../helpers/helperFunctions";
import Loader from "../../utilities/Loader";
import {Icons} from "../../helpers/Icons";
import {useTranslation} from "react-i18next";
import Button from "../../utilities/Button";
import AddModifyType from "./AddModifyType";
import AddModifyCompany from "./AddModifyCompany";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    device: device | null;

    newModifyDevice: device;
    setNewModifyDevice: Dispatch<SetStateAction<device>>;

    deviceImagesUpload: File[];
    setDeviceImagesUpload: Dispatch<SetStateAction<File[]>>;

    currentTypeSpecs: {_id: string, name: string, required: boolean}[];
    setCurrentTypeSpecs: Dispatch<SetStateAction<{_id: string, name: string, required: boolean}[]>>;

    deviceImagesDeleteIDs: string[];
    setDeviceImagesDeleteIDs: Dispatch<SetStateAction<string[]>>;

    setIsChangesSaved: Dispatch<SetStateAction<boolean>>;
}

const AddModifyDevice: FC<IProps> = ({ device, setNewModifyDevice, setDeviceImagesUpload, setCurrentTypeSpecs, newModifyDevice, setDeviceImagesDeleteIDs, currentTypeSpecs, setIsChangesSaved, deviceImagesDeleteIDs, deviceImagesUpload}) =>
{
    const {t} = useTranslation();
    const {accounts, instance} = useMsal();

    const {notificationDispatch, devicePhotos, setDevicePhotos} = useContext(Context);
    const [deviceTypes, setDeviceTypes] = useState<deviceType[]>([]);
    const [deviceCompanies, setDeviceCompanies] = useState<deviceCompany[]>([]);

    const [deviceImagesPreview, setDeviceImagesPreview] = useState<deviceImage[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isAddNewType, setIsAddNewType] = useState<boolean>(false);
    const [isAddNewDeviceCompany, setIsAddNewDeviceCompany] = useState<boolean>(false);

    const [initialDeviceState, setInitialDeviceState] = useState<device>({
        companyID: {_id: "", name: "netum"},
        _id: "",
        description: "",
        images: [],
        leasingEndDate: "",
        model: "",
        requests: [],
        specs: {},
        state: "available",
        typeID: {_id: "", name: ""}
    });

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/webp': ['.webp']
        },
        onDrop: async acceptedFiles =>
        {
            const images: {_id: string, image: string, name?: string}[] = [];

            for(const image of acceptedFiles)
            {
                const base64Image = await blobToBase64(image);

                images.push({_id: `new-${v4()}`, image: base64Image as string, name: `${image.name}+${image.lastModified}+${image.size}`})
            }

            setDeviceImagesUpload(current => [...current, ...acceptedFiles]);
            setDeviceImagesPreview(current => [...current, ...images])
        }
    });

    useEffect(() =>
    {
        setIsChangesSaved((_.isEqual(newModifyDevice, initialDeviceState) || (device !== null && _.isEqual(device, newModifyDevice))) && deviceImagesDeleteIDs.length === 0 && deviceImagesUpload.length === 0)

    }, [newModifyDevice, deviceImagesUpload, deviceImagesDeleteIDs]);

    useEffect(() =>
    {
        (async () =>
        {
            const types = await getAllDeviceTypes(notificationDispatch, {accounts: accounts, instance: instance});
            if (types)
            {
                setDeviceTypes(types);

                if (!device && types[0] !== undefined)
                {
                    setNewModifyDevice(current => {
                        current.typeID = {_id: types[0]._id, name: types[0].name};

                        return current
                    });

                    setInitialDeviceState(current => {
                        current.typeID = {_id: types[0]._id, name: types[0].name};

                        return current
                    });

                    setCurrentTypeSpecs(types[0].typeSpecs);
                } else if(device)
                {
                    setNewModifyDevice(_.clone(device));

                    const index = types.findIndex(type => type._id === (device.typeID as deviceType)._id);

                    setCurrentTypeSpecs(_.clone(types[index].typeSpecs));
/*
                    const deviceImages = await getDeviceImagesByID(device._id, notificationDispatch, {accounts: accounts, instance: instance});
                    if(deviceImages) setDeviceImagesPreview(deviceImages);
*/

                    await getDevicePhotos(device, devicePhotos, setDevicePhotos, notificationDispatch, {accounts: accounts, instance: instance});
                    setDeviceImagesPreview(devicePhotos[getPhotoIndex(devicePhotos, device._id)].images);
                }

                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            const deviceCompanies = await getAllDeviceCompanies(notificationDispatch, {accounts: accounts, instance: instance});
            if (deviceCompanies)
            {
                setDeviceCompanies(deviceCompanies);

                if (!device && deviceCompanies[0] !== undefined)
                {
                    setNewModifyDevice(current => {
                        current.companyID = {_id: deviceCompanies[0]._id, name: deviceCompanies[0].name};

                        return current
                    });

                    setInitialDeviceState(current => {
                        current.companyID = {_id: deviceCompanies[0]._id, name: deviceCompanies[0].name};

                        return current
                    });

                }

                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() =>
    {
        if(deviceTypes.length)
        {
            const index = deviceTypes.findIndex(type => type._id === (newModifyDevice.typeID as deviceType)._id);
            setCurrentTypeSpecs(deviceTypes[index].typeSpecs);
        }
    }, [newModifyDevice.typeID]);


    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>, isSpecs: boolean = false) => {
        let {name, value} = e.target;

        if(name === "state" && newModifyDevice.state === "free")
        {
            setNewModifyDevice(prev =>
            {
                prev.leasingEndDate = "";
                return prev;
            })
        }

        if (name === "typeID" || name === "companyID")
        {
            value = JSON.parse(value);

            if(name === "typeID") setNewModifyDevice(prev => {
                prev.specs = {};
                return prev;
            })
        }

        setNewModifyDevice(prev => isSpecs ? {...prev, specs: {...prev.specs, [name]: value}}
            : {...prev, [name]: value});

       if(isSpecs) setInitialDeviceState(prev => ({...prev, specs: {...prev.specs, [name]: ""}}));
    };

    const handleImageArrayDelete = (imageID: string, imageName: string | undefined) =>
    {
        if(!imageID.startsWith("new-")) setDeviceImagesDeleteIDs(current => [...current, imageID]);

        setDeviceImagesPreview(current => current.filter(image => image._id !== imageID));
        if(imageName) setDeviceImagesUpload(current => current.filter(image => `${image.name}+${image.lastModified}+${image.size}` !== imageName));
    }

    return (
        <div className={styles.main_container}>
            {!isLoading ?
                <div className={styles.container}>
                    <div className={styles.general_container}>

                        <label>
                            {t("device.type")}*
                            <select name="typeID" value={JSON.stringify(newModifyDevice.typeID)}
                                    onChange={e => handleChange(e)}>
                                {!deviceTypes.length ? <option disabled={true} value={`{"_id":"","name":""}`}>{t("device.selectType")}</option> : null}
                                {deviceTypes.map(type =>
                                    <option key={type._id}
                                            value={`{"_id":"${type._id}","name":"${type.name}"}`}>{type.name}</option>)}
                            </select>
                            <div className={styles.add_new_type}><Button btnMini={true} onClick={() => setIsAddNewType(true)}>{t("device.addNewType")}</Button></div>
                        </label>

                        <label>
                            {t("device.model")}*
                            <input value={newModifyDevice.model} type="text" name="model"
                                   onChange={e => handleChange(e)}/>
                        </label>
                        <label>
                            {t("device.description")}
                            <textarea value={newModifyDevice.description} name="description"
                                      onChange={e => handleChange(e)}/>
                        </label>
                        {newModifyDevice.state !== "free" ?
                            <label>
                                {t("device.leasingEndDate")}
                                <input value={newModifyDevice.leasingEndDate} type="text" name="leasingEndDate"
                                       onChange={e => handleChange(e)}/>
                            </label>
                        : null}
                        <label>
                            {t("app.state")}*
                            <select name="state" value={newModifyDevice.state} onChange={e => handleChange(e)}>
                                {["free", "available", "reserved", "sold"].map((state, index) =>
                                    <option key={index} value={state}>{handleDeviceStateTranslation(state)}</option>
                                )}
                            </select>
                        </label>
                        <label>
                            {t("device.company")}*
                            <select name="companyID" value={JSON.stringify(newModifyDevice.companyID)} onChange={e => handleChange(e)}>
                                {deviceCompanies.map(company=>
                                    <option key={company._id}
                                            value={`{"_id":"${company._id}","name":"${company.name}"}`}>{upperCaseFirstLetter(company.name)}</option>
                                )}
                            </select>
                            <div className={styles.add_new_type}><Button btnMini={true} onClick={() => setIsAddNewDeviceCompany(true)}>{t("device.addNewCompany")}</Button></div>
                        </label>
                        <label>
                            {t("device.images")}
                            <section className={styles.dropbox_container}>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>{t("device.imageDragDrop")}</p>
                                </div>
                            </section>
                        </label>
                        {deviceImagesPreview.length ?
                        <div className={styles.images_preview}>
                            {deviceImagesPreview.map(image =>
                                <div key={image._id} className={styles.image_card}>
                                    <button className={styles.delete_image} onClick={() => handleImageArrayDelete(image._id, image?.name)}>{Icons.delete}</button>
                                    <img src={image.image}  alt="" />
                                </div>
                            )}
                        </div>
                        : null}

                    </div>

                    {currentTypeSpecs.length ?
                    <div className={styles.specs_container}>
                        {t("device.specs")}
                        <div className={styles.specs_list}>
                            {deviceTypes.filter(type => type._id === newModifyDevice.typeID._id)[0].typeSpecs
                                .map((spec, index) =>
                                        <label key={index} className={styles.spec}>
                                            <span>{spec.name}{spec.required ? "*" : null}</span>
                                            {spec.isSelector ?
                                                <select name={spec.name} onChange={e => handleChange(e, true)}
                                                        value={newModifyDevice.specs && newModifyDevice.specs[spec.name as keyof Object] !== undefined ?
                                                            newModifyDevice.specs[spec.name as keyof Object].toString() : ""}>
                                                    <option disabled={true} value="">{t("app.selectOption")}</option>
                                                    {newModifyDevice.specs && newModifyDevice.specs[spec.name as keyof Object] !== undefined &&
                                                    !spec.selectorArray.filter(specOption => specOption.option.toLowerCase() === newModifyDevice.specs[spec.name as keyof Object].toString().toLowerCase()).length ?
                                                        <option disabled={true}>{newModifyDevice.specs[spec.name as keyof Object].toString()}</option> : null}
                                                    {spec.selectorArray.map(specOption =>
                                                        <option key={specOption._id} value={specOption.option}>{specOption.option}</option>
                                                    )}
                                                </select>
                                            :
                                                <input
                                                    value={newModifyDevice.specs && newModifyDevice.specs[spec.name as keyof Object] !== undefined ?
                                                        newModifyDevice.specs[spec.name as keyof Object].toString() : ""}
                                                    type="text" required={spec.required} name={spec.name}
                                                    onChange={e => handleChange(e, true)}/>
                                            }
                                        </label>
                                )}
                        </div>
                    </div>
                    : null}

                </div>

            : <div className={styles.loader}><Loader /></div>}

            {isAddNewType &&
                <AddModifyType setIsAddModifyType={setIsAddNewType} isAddModifyDevice={true}
                               types={deviceTypes}
                               setTypes={setDeviceTypes}
                               type={null}
                               setType={() => null}
                               setNewModifyDevice={setNewModifyDevice}
                               setCurrentTypeSpecs={setCurrentTypeSpecs} />
            }

            {isAddNewDeviceCompany &&
                <AddModifyCompany setIsAddModifyCompany={setIsAddNewDeviceCompany} isAddModifyDevice={true}
                               deviceCompanies={deviceCompanies}
                               setDeviceCompanies={setDeviceCompanies}
                               deviceCompany={null}
                               setDeviceCompany={() => null}
                               setNewModifyDevice={setNewModifyDevice} />
            }

        </div>
    );
}

export default AddModifyDevice;
