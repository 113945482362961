import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from './css/RequestCard.module.css';
import {device, deviceRequests} from "../helpers/types";
import {Icons} from "../helpers/Icons";
import Button from "./Button";
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import {deleteRequestByID, manageRequestByID, manageUserRequestByID} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import ConfirmActionScreen from "./ConfirmActionScreen";
import {extractFullNameFromEmail} from "../helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    request: deviceRequests;
    requests: deviceRequests[];
    setRequests: Dispatch<SetStateAction<deviceRequests[]>>;
    isControlPanel?: boolean;
}

const RequestCard: FC<IProps> = ({request, isControlPanel = false, requests, setRequests}) =>
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);

    const [isViewMore, setIsViewMore] = useState<boolean>(false);
    const [isManage, setIsManage] = useState<boolean>(false);
    const [isDeleteRequest, setIsDeleteRequest] = useState<boolean>(false);

    const [adminMessage, setAdminMessage] = useState<string>("");
    const [decision, setDecision] = useState<string>("accepted");
    const [userMessage, setUserMessage] = useState<string>("");

    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

    const [isRequestDeleting, setIsRequestDeleting] = useState<boolean>(false);

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        if(isViewMore)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[isViewMore]);


    useEffect(() =>
    {
        setAdminMessage(request.adminMessage);
        setUserMessage(request.userMessage);
    }, [request]);

    useEffect(() =>
    {
        if(isManage && !isControlPanel) setIsSaveDisabled(userMessage.toLowerCase() === request.userMessage.toLowerCase())

    }, [adminMessage, userMessage, isManage]);

    const handleCloseModalManage = () =>
    {
        if(isManage)
        {
            setIsManage(false);
            setAdminMessage(request.adminMessage);
            setUserMessage(request.userMessage);
            setIsSaveDisabled(false)
            return;
        }

        setIsViewMore(false);
    }

    const handleManageSave = async () =>
    {
        if(!isManage)
        {
            setIsManage(true);
            return;
        }

        const managedRequest = isControlPanel ? await manageRequestByID({_id: request._id, adminMessage: adminMessage, decision: decision}, notificationDispatch, {accounts: accounts, instance: instance})
            : await manageUserRequestByID({_id: request._id, userMessage: userMessage}, notificationDispatch, {accounts: accounts, instance: instance});

        if(managedRequest)
        {
            const index = requests.findIndex(currRequest => currRequest._id === managedRequest.request._id);

            setRequests(current =>
            {
                current[index] = managedRequest.request;
                return current;
            })
        }

        handleCloseModal();

    }

    const handleCloseModal = () =>
    {
        setIsManage(false);
        setIsViewMore(false);
        setAdminMessage(request.adminMessage);
        setUserMessage(request.userMessage);
        setIsSaveDisabled(false);
    }

    const handleDeleteRequest = async () =>
    {
        setIsRequestDeleting(true);
        const deletedRequest = await deleteRequestByID(request._id, notificationDispatch, {accounts: accounts, instance: instance});

        if(deletedRequest)
        {
            setRequests(current => current.filter(currRequest => currRequest._id !== request._id));
            setIsDeleteRequest(false);
        }

        setIsRequestDeleting(false)
    }

    return (
        <div className={`${isControlPanel ? styles.container_control_panel : styles.container} ${styles[request.state]}`}>

            <div className={styles.card} onClick={() => setIsViewMore(true)}>
                <span className={styles.device_header}>
                    <a target="_blank" onClick={e => e.stopPropagation()} href={`/device/${(request.deviceID as device)._id}`}><span>{(request.deviceID as device).model}</span></a></span>
                <div className={styles.general_info}>
                    <div className={styles.info_item}><span>ID: </span>{(request.deviceID as device).toriID}</div>
                    <div className={styles.info_item}><span>{t("device.status")}: </span>{t(`app.${request.state}`)}</div>
                    {isControlPanel && <span className={styles.info_item}>{t("app.user")}: <a href={`mailto:${request.userEmail}`}>{extractFullNameFromEmail(request.userEmail)}</a></span>}
                    <div className={styles.message_container}>
                        <span>{isControlPanel ? t("requests.userMessage") : t("requests.yourMessage")}</span>
                        {request.userMessage.length ? <p>{request.userMessage}</p> : <span className={styles.no_message}>{t("requests.noMessage")}</span>}
                    </div>
                    <div className={styles.message_container}>
                        <span>{t("requests.adminMessage")}</span>
                        {request.adminMessage.length ? <p>{request.adminMessage}</p> : <span className={styles.no_message}>{t("requests.noMessage")}</span>}
                    </div>

                    <div className={styles.controls_main}>
                        {(request.state === "pending" || isControlPanel) && <Button btnType="danger" onClick={e => {e.stopPropagation();setIsDeleteRequest(true);}}>{isControlPanel ? t("app.delete") : t("app.cancel")}</Button>}
                        <Button onClick={() => setIsViewMore(true)}>{t("app.viewMore")}</Button>
                    </div>
                </div>
            </div>

            {isViewMore &&
                <div className={absoluteStyles.window_container} onClick={() => handleCloseModal()} onTouchEnd={() => handleCloseModal()}>
                    <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                        <div className={absoluteStyles.info_content}>

                            <div className={styles.view_more_container}>
                                <span className={styles.device_header}><a target="_blank" href={`/device/${(request.deviceID as device)._id}`}>{(request.deviceID as device).model}{Icons.link}</a></span>

                                <div className={styles.general_info_view_more}>

                                    <span>ID: {(request.deviceID as device).toriID}</span>
                                    {isControlPanel && <span className={styles.user_email}>User: <a href={`mailto:${request.userEmail}`}>{extractFullNameFromEmail(request.userEmail)}</a></span>}

                                    {isControlPanel && isManage && request.state === "pending" ?
                                        <label>
                                            {t("device.status")}:
                                            <select value={decision} onChange={e => setDecision(e.target.value)}>
                                                <option value="rejected">{t("app.reject")}</option>
                                                <option value="accepted">{t("app.accept")}</option>
                                            </select>
                                        </label>
                                        : <span className={`${styles[`view_more_${request.state}`]} ${styles.view_more_state}`}>{t("device.status")}: {t(`app.${request.state}`)}</span>
                                    }

                                    <div className={`${styles.view_more_messages}`}>
                                        <span>{isControlPanel ? t("requests.userMessage") : t("requests.yourMessage")}</span>
                                        {isManage && !isControlPanel ?
                                            <textarea
                                                      onChange={e => setUserMessage(e.target.value)}
                                                      className={styles.manage_textarea} defaultValue={userMessage} />
                                        :
                                            <p>{request.userMessage}</p>
                                        }
                                    </div>
                                    <div className={`${styles.view_more_messages}`}>
                                        <span>{t("requests.adminMessage")}</span>
                                        {isManage && isControlPanel ?
                                            <textarea onChange={e => setAdminMessage(e.target.value)}
                                                className={styles.manage_textarea} defaultValue={adminMessage} />
                                            :
                                            <p>{request.adminMessage}</p>
                                        }
                                    </div>
                                </div>

                                <div className={styles.controls}>
                                    <Button onClick={handleCloseModalManage} btnType="danger">{isManage ? t("app.cancel") : t("app.close")}</Button>
                                    {request.state === "pending" && <Button onClick={handleManageSave} disabled={isSaveDisabled}>{isManage ? t("app.save") : t("app.edit")}</Button>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }

            {isDeleteRequest &&
                <ConfirmActionScreen close={() => setIsDeleteRequest(false)} isDisableButtons={isRequestDeleting}
                                     confirm={handleDeleteRequest}
                                     message={
                                         <>
                                             {t("requests.deleteMessage1")} {isControlPanel ? t("app.delete") : t("app.cancel")} {isControlPanel ? <a href={`mailto:${request.userEmail}`}>{extractFullNameFromEmail(request.userEmail)}</a> : ""} {t("requests.deleteMessage2")} {(request.deviceID as device).model}?
                                         </>
                                     }
                                     title={`${t("app.warning")}!`} />
            }
        </div>
    );
}

export default RequestCard;
