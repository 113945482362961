import React, {useEffect, useState} from 'react';
import {useIsAuthenticated} from "@azure/msal-react";
import Marketplace from "./Marketplace";
import ToriPasswordChecker from "./ToriPasswordChecker";
import {Navigate, useLocation} from "react-router-dom";
function Main({})
{
    const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean>(false);
    const isAuthenticated = useIsAuthenticated();

    const location = useLocation();

    return (
        <div>
            {(isAuthenticated || isPasswordCorrect || sessionStorage.getItem("toriPassCorrect") !== null) ?
                <>{location.pathname === "/" ? <Marketplace /> : <Navigate to={location} />}</>
            : <ToriPasswordChecker setIsPasswordCorrect={setIsPasswordCorrect} />}
        </div>
    );
}

export default Main;
