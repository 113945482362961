import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import AddModifyDevice from "./AddModifyDevice";
import styles from './css/ManageDevice.module.css';
import DeviceRequests from "./DeviceRequests";
import Button from "../../utilities/Button";
import { device } from '../../helpers/types';
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import {useTranslation} from "react-i18next";
import {inputValidation} from "../../helpers/helperFunctions";
import {addDevice, deleteDeviceImages, modifyDeviceByID} from "../../helpers/APIEnpoints";
import Context from "../../helpers/Context";
import {ClipLoader} from "react-spinners";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    setIsAddManage: Dispatch<SetStateAction<boolean>>;

    devices: device[];
    setDevices: Dispatch<SetStateAction<device[]>>;

    device: device | null;
    setDevice: Dispatch<SetStateAction<device | null>>;
}

const ManageDevice: FC<IProps> = ({setIsAddManage, setDevices, devices, setDevice, device}) =>
{
    const {notificationDispatch} = useContext(Context);
    const {accounts, instance} = useMsal();

    const {t} = useTranslation();
    const [option, setOption] = useState<string>("general");

    const [newModifyDevice, setNewModifyDevice] = useState<device>({
        companyID: {_id: "", name: "netum"},
        _id: "",
        description: "",
        images: [],
        leasingEndDate: "",
        model: "",
        requests: [],
        specs: {},
        state: "available",
        typeID: {_id: "", name: ""}
    });
    const [currentTypeSpecs, setCurrentTypeSpecs] = useState<{_id: string, name: string, required: boolean}[]>([]);
    const [deviceImagesDeleteIDs, setDeviceImagesDeleteIDs] = useState<string[]>([]);
    const [deviceImagesUpload, setDeviceImagesUpload] = useState<File[]>([])

    const inputsToValidate = [{value: newModifyDevice.model, label: t("device.model")},
        {value: newModifyDevice.state, label: t("app.state")},
        ...currentTypeSpecs.filter(spec => spec.required)
            .map(spec => {return {value: newModifyDevice.specs && newModifyDevice.specs[spec.name as keyof Object], label: spec.name}})];

    const [isModifyingAdding, setIsModifyingAdding] = useState<boolean>(false);
    const [isChangesSaved, setIsChangesSaved] = useState<boolean>(true);

    const [isShowChangesNotSaved, setIsShowChangesNotSaved] = useState<boolean>(false);


    const renderSwitch = () =>
    {
        switch (option)
        {
            case "general":
                return <AddModifyDevice
                                 setNewModifyDevice={setNewModifyDevice}
                                 device={device} currentTypeSpecs={currentTypeSpecs}
                                 newModifyDevice={newModifyDevice}
                                 setCurrentTypeSpecs={setCurrentTypeSpecs}
                                 deviceImagesUpload={deviceImagesUpload}
                                 setDeviceImagesUpload={setDeviceImagesUpload}
                                 deviceImagesDeleteIDs={deviceImagesDeleteIDs}
                                 setDeviceImagesDeleteIDs={setDeviceImagesDeleteIDs}
                                 setIsChangesSaved={setIsChangesSaved}/>
            case "requests":
                return <DeviceRequests deviceID={device!._id}
                                       devices={devices}
                                       setDevices={setDevices} />

        }
    }

    const handleClose = () =>
    {
        setIsAddManage(false);
        setDevice(null);
    }

    const deviceHandleAddModify = async () =>
    {
        setIsModifyingAdding(true);
        if(inputValidation(inputsToValidate, notificationDispatch))
        {
            if (device)
            {
                const index = devices.findIndex(device => device._id === newModifyDevice._id);

                if(deviceImagesDeleteIDs.length)
                {
                    await deleteDeviceImages(deviceImagesDeleteIDs, newModifyDevice._id, notificationDispatch, {accounts: accounts, instance: instance});
                }

                const modifiedDevice = await modifyDeviceByID({...newModifyDevice, images: deviceImagesUpload}, notificationDispatch, {accounts: accounts, instance: instance});

                if (modifiedDevice)
                {
                    setDevices(current =>
                    {
                        current[index] = modifiedDevice;

                        return current;
                    })

                    handleClose();
                }
            } else
            {
                const addedDevice = await addDevice({...newModifyDevice, images: deviceImagesUpload}, notificationDispatch, {accounts: accounts, instance: instance});

                if (addedDevice)
                {
                    setDevices(current => [...current, addedDevice]);
                    handleClose();
                }
            }
            setIsChangesSaved(true);
        }
        setIsModifyingAdding(false);
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => isModifyingAdding ? null : isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)} onTouchEnd={() => isModifyingAdding ? null : isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)}>
            <div className={absoluteStyles.window_container_content} style={{padding: 0}} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.container}>

                        <div className={styles.controls}>
                            <Button btnType={option === "general" ? "primary" : "secondary"} onClick={() => setOption("general")}>{t("controlPanel.generalInfo")}</Button>
                            {device && <Button btnType={option === "requests" ? "primary" : "secondary"} onClick={() => setOption("requests")}>{t("controlPanel.requests")}</Button>}
                        </div>

                        <div className={styles.render_switch_container}>{renderSwitch()}</div>

                        <div className={styles.render_controls}>
                            <Button btnType="danger" disabled={isModifyingAdding} onClick={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)}>{t("app.close")}</Button>
                            {option === "general" && <Button disabled={isModifyingAdding} onClick={() => deviceHandleAddModify()}>{isModifyingAdding ? <div className={styles.saving_state}>{t("app.save")} <ClipLoader color="var(--success)"  size="25px" /></div> : `${t("app.save")}${!isChangesSaved ? "*" : ""}`}</Button>}
                        </div>

                        {isShowChangesNotSaved &&
                            <ConfirmActionScreen
                                close={() => setIsShowChangesNotSaved(false)}
                                confirm={handleClose}
                                title={t("notSavedModal.title")}
                                message={
                                    <div className={styles.not_saved_message}>
                                        <span>{t("notSavedModal.warningMessage")}</span>
                                        <span style={{color: "var(--error)"}}><strong>{t("notSavedModal.warningMessageSubtitle")}</strong></span>
                                    </div>
                                }/>
                        }

                    </div>

                </div>
            </div>
        </div>
    );
}

export default ManageDevice;
