import React from 'react';
import styles from './css/ControlPanel.module.css';
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Devices from "./Devices";
import Types from "./Types";
import Requests from "./Requests";
import ToriPassword from "./ToriPassword";
import Companies from "./Companies";


const ControlPanel = ({}) =>
{
    const {option} = useParams();
    const {t} = useTranslation();

    const renderSwitch = () =>
    {
        switch (option)
        {
            case "devices":
                return <Devices />
            case "requests":
                return <Requests />
            case "types":
                return <Types />
            case "companies":
                return <Companies />
            case "password":
                return <ToriPassword />
            default:
                return <p>{t("app.wrongRoute")}</p>
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Button btnType={option !== "devices" ? "secondary" : "primary"} href="/control-panel/devices">{t("controlPanel.devices")}</Button>
                <Button btnType={option !== "requests" ? "secondary" : "primary"} href="/control-panel/requests">{t("controlPanel.requests")}</Button>
                <Button btnType={option !== "types" ? "secondary" : "primary"} href="/control-panel/types">{t("controlPanel.types")}</Button>
                <Button btnType={option !== "companies" ? "secondary" : "primary"} href="/control-panel/companies">{t("controlPanel.companies")}</Button>
                <Button btnType={option !== "password" ? "secondary" : "primary"} href="/control-panel/password">{t("controlPanel.toriPassword")}</Button>
            </div>

            {renderSwitch()}
        </div>
    );
}

export default ControlPanel;
