import React, {useContext, useEffect, useState, useRef} from 'react';
import styles from './css/Marketplace.module.css';
import {device, deviceCompany, deviceType} from "../helpers/types";
import {getAllDeviceCompanies, getAllDevices, getAllDeviceTypes} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import DeviceCard from "../utilities/DeviceCard";
import MultiSelector from "../utilities/MultiSelector";
import Search from "../utilities/Search";
import {
    filterDevicesMultiSelectorSpecs,
    filterSortDevices,
    resetFilter, upperCaseFirstLetter
} from "../helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import Button from "../utilities/Button";
import DeviceRequestModal from "../utilities/DeviceRequestModal";
import {Icons} from "../helpers/Icons";
import {useSearchParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {PulseLoader} from "react-spinners";
import {useMsal} from "@azure/msal-react";
import Loader from "../utilities/Loader";
import FirstTimeBanner from "../utilities/FirstTimeBanner";

const Marketplace = ({}) =>
{
    const {notificationDispatch, isLoading, setIsLoading} = useContext(Context);
    const [devices, setDevices] = useState<device[]>([]);

    const [requestedDevice, setRequestedDevice] = useState<device | null>(null);

    const [types, setTypes] = useState<deviceType[]>([]);
    const [deviceCompanies, setDeviceCompanies] = useState<deviceCompany[]>([]);

    const [selectedType, setSelectedType] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<string>("available");
    const [selectedCompany, setSelectedCompany] = useState<string>("");
    const [selectedSpecs, setSelectedSpecs] = useState<{name: string, value: string}[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const [sortBy, setSortBy] = useState<string>("nameAZ");

    const properTypeSpecs = types.length && selectedType.length ? types[types.findIndex(type => type.name.toLowerCase() === selectedType.toLowerCase())].typeSpecs : [];

    const filteredDevices = filterSortDevices(devices,{searchQuery: searchQuery, selectedStatus: selectedStatus, selectedType: selectedType, selectedSpecs: selectedSpecs, selectedCompany: selectedCompany}, sortBy);
    const specsDevicesFiltered = filterDevicesMultiSelectorSpecs(devices, {searchQuery: searchQuery, selectedStatus: selectedStatus, selectedType: selectedType, selectedSpecs, properTypeSpecsLength: properTypeSpecs.length, selectedCompany: selectedCompany})

    const {t} = useTranslation();
    const {accounts, instance} = useMsal();

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const filtersRef = useRef<HTMLDivElement>(null);

    const [isMobile, setIsMobile] = useState<boolean>(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const [secondSlice, setSecondSlice] = useState<number>(20);

    const [isShowBanner, setIsShowBanner] = useState<boolean>(true);

    useEffect(() =>
    {
        if(localStorage.getItem("bannerHidden") && !sessionStorage.getItem("hideBannerSession"))
        {
            setIsShowBanner(localStorage.getItem("bannerHidden") !== "1");
        } else if(sessionStorage.getItem("hideBannerSession")) setIsShowBanner(false)
    }, []);

    useEffect(() => {
        if(searchParams.has("status"))
        {
            const status = searchParams.get("status")!.toLowerCase();
            if(["", "all", "free", "available", "reserved"].includes(status))
            {
                if(!status.length) setSearchParams({status: "all"})
                setSelectedStatus(status === "all" ? "" : status);
            }
        } else
        {
            setSearchParams({status: selectedStatus})
        }
    }, []);

    useEffect(() =>
    {
        if(searchParams.has("type"))
        {
            const type = searchParams.get("type")!.toLowerCase();
            if(types.map(type => type.name.toLowerCase()).includes(type))
            {
                setSelectedType(type)
            }
        }
    }, [types]);

    useEffect(() =>
    {
        if(searchParams.has("company"))
        {
            const company = searchParams.get("company")!.toLowerCase();
            if([...new Set(devices.filter(device => device.state !== "sold").map(device => (device.companyID as deviceCompany).name.toLowerCase()))].includes(company))
            {
                setSelectedType(company)
            }
        }
    }, [devices]);

    useEffect(() =>
    {
        if((showFilters && isMobile) || requestedDevice || isShowBanner)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[showFilters, requestedDevice, isShowBanner, isMobile]);

    useEffect(() =>
    {
        setShowFilters(window.innerWidth >= 1367);
        setIsMobile(window.innerWidth <= 1366);
    }, []);

    useEffect(() =>
    {
        window.addEventListener("resize", ()=>
        {
            if(window.innerWidth >= 1367)
            {
                setShowFilters(true);
                setIsMobile(false);
            }
        });

        return window.removeEventListener("resize", () => {});

    }, [window.innerWidth]);

    useEffect(() =>
    {
        if(filtersRef.current)
        {
            const el = filtersRef.current;

            if(showFilters)
            {
                el.classList.replace(styles.filters_main_container_hide, styles.filters_main_container_show);
            } else el.classList.replace(styles.filters_main_container_show, styles.filters_main_container_hide);
        }

    }, [showFilters]);

    useEffect(() =>
    {
        (async () =>
        {
            const types = await getAllDeviceTypes(notificationDispatch, {accounts: accounts, instance: instance});
            if(types) setTypes(types);
        })();
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            const deviceCompanies = await getAllDeviceCompanies(notificationDispatch, {accounts: accounts, instance: instance});
            if(deviceCompanies) setDeviceCompanies(deviceCompanies);
        })();
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            const devices = await getAllDevices(notificationDispatch, {accounts: accounts, instance: instance});

            if(devices) setDevices(devices);
        })();
    }, []);

    useEffect(() =>
    {
        setIsLoading(!devices.length && !deviceCompanies.length && !types.length);
    }, [devices, deviceCompanies, types]);

    useEffect(() =>
    {
        setSelectedSpecs([]);
    }, [selectedType]);

    const handleRequestClose = () =>
    {
        setRequestedDevice(null);
    }

    return (
        <div className={styles.container}>

            <div className={`${styles.filters_main_container} ${styles.filters_main_container_show}`} ref={filtersRef}
                 onClick={e => {e.stopPropagation(); setShowFilters(false)}} onTouchEnd={e => {e.stopPropagation(); setShowFilters(false)}}>
                <div className={styles.filters_container} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                    <div className={styles.filters_controls}>
                        <Button btnType="secondary" btnMini={true} disabled={isLoading} onClick={() =>
                            resetFilter([setSelectedSpecs], [setSelectedType, setSelectedStatus, setSelectedCompany], searchParams, setSearchParams, ["status", "type", "company"])}>{t("app.filterReset")}</Button>
                        <div className={styles.filters_close}><Button btnType="danger" onClick={() => setShowFilters(false)}>{t("app.close")}</Button></div>
                    </div>

                    <MultiSelector label={t("device.company")} isQueryParams={true} queryLabel="company" isDisabled={isLoading}
                                   data={deviceCompanies.map(company => company.name.toLowerCase())}
                                   selectedOptions={selectedCompany}
                                   setSelectedOptions={setSelectedCompany} />
                    <MultiSelector label={t("device.type")} isQueryParams={true} queryLabel="type" isDisabled={isLoading}
                                   data={[...new Set(types.map(type => type.name.toLowerCase()))]}
                                   selectedOptions={selectedType}
                                   setSelectedOptions={setSelectedType} />
                    <MultiSelector label={t("device.status")} isApplyLocale={true} isQueryParams={true} queryLabel="status" isDisabled={isLoading}
                                   data={["free", "available", "reserved"]}
                                   selectedOptions={selectedStatus}
                                   setSelectedOptions={setSelectedStatus} />
                    {selectedType.length ?
                        <div className={styles.selected_type_container}>
                            {properTypeSpecs.map(spec =>
                                <MultiSelector key={spec._id} label={spec.name} isMulti={true} isObjectMulti={true} isDisabled={isLoading}
                                                              data={[...new Set(specsDevicesFiltered
                                                                  .map(device => device.specs && device.specs[spec.name as keyof Object] !== undefined ?
                                                                      device.specs[spec.name as keyof Object].toLowerCase() : ""))]}
                                                              selectedOptions={selectedSpecs}
                                                              setSelectedOptions={setSelectedSpecs} />
                            )}
                        </div>
                    : null}
                </div>
            </div>

            <div className={styles.devices_container}>

                <div className={styles.top}>
                    <span className={styles.header}>{t("marketplace.availableDevices")} ({filteredDevices.length})</span>
                    <div className={styles.sort_search}>
                        <div className={styles.search_filters}>
                            <div className={styles.search}><Search onChange={setSearchQuery} placeholder={t("app.search")} isDisabled={isLoading} /></div>

                            <button className={styles.show_filters} onClick={() =>
                            {
                                setShowFilters(true);
                                window.scrollTo(0, 0);
                            }}>{t("app.filters")} {Icons.filter}</button>
                        </div>

                            <MultiSelector label={t("app.sortBy")} isOptionAll={false} isSearchAvailable={false} isApplyLocale={true} width="254px" isDisabled={isLoading}
                                           data={["nameAZ", "nameZA", "statusAZ", "statusZA", "leasingAsc", "leasingDesc"]}
                                           selectedOptions={sortBy}
                                           setSelectedOptions={setSortBy} />
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={secondSlice <= filteredDevices.length ? secondSlice : filteredDevices.length}
                    next={() => setSecondSlice(current => current+20)}
                    hasMore={secondSlice < filteredDevices.length}
                    className={styles.devices_list_content}
                    loader={<div className={styles.loader}><PulseLoader color="var(--btnColor)" size={15} /></div>}>

                    <div className={styles.devices_list}>
                        {(!isLoading && filteredDevices.length) ? filteredDevices.slice(0, secondSlice)
                            .map(device => <DeviceCard key={device._id} device={device} onRequest={setRequestedDevice} />)
                        : isLoading ? <Loader /> : <p style={{fontWeight: 600, width: "100%", textAlign: "center"}}>{t("app.noResults")}</p>}
                    </div>
                </InfiniteScroll>
            </div>

            {requestedDevice &&
                <DeviceRequestModal device={requestedDevice} handleClose={handleRequestClose} isDeviceArray={true} setDevices={setDevices} devices={devices} />
            }

            {isShowBanner && <FirstTimeBanner setIsShown={setIsShowBanner} />}

        </div>
    );
}

export default Marketplace;
