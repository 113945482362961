import React, {useContext, useEffect} from 'react';
import './index.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {loginRequest} from "./authConfig";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
    useMsalAuthentication
} from "@azure/msal-react";
import Context from "./helpers/Context";
import './i18n';
import {useTranslation} from "react-i18next";
import {EventType, InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";
import NavBar from "./components/NavBar";
import ControlPanel from './components/controlPanel/ControlPanel';
import Device from "./components/Device";
import 'react-datepicker/dist/react-datepicker.css'
import User from "./components/User";
import Main from "./components/Main";
import {isAdmin} from "./helpers/checkers";
import Marketplace from "./components/Marketplace";

function App()
{
    const { instance, accounts } = useMsal();
    const {setGroupsID, groupsID} = useContext(Context);
    const isAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();
    const {login, error} = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() =>
    {
        (async () =>
        {
            await instance.initialize().then(async () =>
            {
                if(instance.getAllAccounts().length)
                {
                    const loginHint = instance.getAllAccounts().filter(account => account.tenantId === process.env.REACT_APP_TENANT_ID)[0].idTokenClaims?.login_hint;
                    const accountHomeID = instance.getAllAccounts().filter(account => account.tenantId === process.env.REACT_APP_TENANT_ID)[0].homeAccountId;

                    if(loginHint)
                    {
                        await instance.ssoSilent({...loginRequest, loginHint: loginHint})
                            .catch(e =>
                            {
                                if(e instanceof InteractionRequiredAuthError)
                                {
                                    instance.handleRedirectPromise().then(authResult=>
                                    {
                                        const account = instance.getActiveAccount();
                                        if(!account)
                                        {
                                            instance.loginRedirect();
                                        }
                                    }).catch(err=>{
                                        console.log(err);
                                    });
                                }
                            });

                        if(accountHomeID) instance.setActiveAccount(instance.getAccountByHomeId(accountHomeID));
                    }
                }
            });
        })();
    }, []);

/*
    useEffect(() =>
    {
        (async () =>
        {
            console.log(error)

            if (error instanceof InteractionRequiredAuthError) await login(InteractionType.Redirect, loginRequest);
        })();
    }, [error]);
*/

    useEffect(() =>
    {
        (async () =>
        {
            if(isAuthenticated)
            {
                if(accounts[0].idTokenClaims !== undefined && Array.isArray(accounts[0].idTokenClaims.groups)) setGroupsID(accounts[0].idTokenClaims.groups);
            }
        })();
    }, [isAuthenticated]);

  return (
      <Router>
          <NavBar />
              <div className="main-container">
                  <Routes>
                      <Route path="/" element={
                          <>
                              <AuthenticatedTemplate>{<Marketplace />}</AuthenticatedTemplate>
                              <UnauthenticatedTemplate><Main /></UnauthenticatedTemplate>
                          </>} />
                      <Route path="/device/:id" element={
                          <>
                              <AuthenticatedTemplate><Device /></AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  {sessionStorage.getItem("toriPassCorrect") !== null ?
                                      <Device />
                                  :
                                      <Main />
                                  }
                              </UnauthenticatedTemplate>
                          </>
                      } />
                      <Route path="/user" element={
                          <>
                              <AuthenticatedTemplate>{<User />}</AuthenticatedTemplate>
                              <UnauthenticatedTemplate><p>{t("app.unAuthError")}</p></UnauthenticatedTemplate>
                          </>
                      } />
                      <Route path="/control-panel/" element={<Navigate to="/control-panel/devices" replace={true}/>} />
                      <Route path="/control-panel/:option" element={
                          <>
                              <AuthenticatedTemplate>
                                   {isAdmin(groupsID) ? <ControlPanel /> : <p>{t("app.accessError")}</p>}
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>} />
                  </Routes>
              </div>
      </Router>
  );
}

export default App;
