import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Requests.module.css';
import Context from "../../helpers/Context";
import {deviceRequests} from "../../helpers/types";
import {getAllRequests} from "../../helpers/APIEnpoints";
import MultiSelector from "../../utilities/MultiSelector";
import RequestCard from "../../utilities/RequestCard";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

function Requests()
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const [requests, setRequests] = useState<deviceRequests[]>([]);

    const [stateFilter, setStateFilter] = useState<string>("pending");

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        (async () =>
        {
            const requests = await getAllRequests(notificationDispatch, {accounts: accounts, instance: instance});
            if(requests) setRequests(requests);
        })();
    }, []);

    return (
        <div className={styles.container}>
            <MultiSelector data={["rejected", "pending", "accepted"]} width="254px" isApplyLocale={true}
                           label={t("device.status")}
                           selectedOptions={stateFilter}
                           setSelectedOptions={setStateFilter} />

            <div className={styles.requests_list}>
                {requests.filter(request => stateFilter.length ? request.state.toLowerCase() === stateFilter.toLowerCase() : request).length ?
                    requests.filter(request => stateFilter.length ? request.state.toLowerCase() === stateFilter.toLowerCase() : request).map(request =>
                    <RequestCard key={request._id} request={request} setRequests={setRequests} requests={requests} isControlPanel={true} />
                ) : <p>{t("app.noResults")}</p>}
            </div>
        </div>
    );
}

export default Requests;
