import React, {FC, ReactNode} from 'react';
import absoluteStyles from './css/AbsoluteWindowStyles.module.css';
import styles from './css/ConfirmActionScreen.module.css';
import Button from "./Button";
import {useTranslation} from "react-i18next";

interface IProps
{
    title: ReactNode;
    message: ReactNode;
    confirm: () => void;
    close: () => void;
    isDisableButtons?: boolean;
}

const ConfirmActionScreen: FC<IProps> = ({title, message, confirm, close, isDisableButtons}) =>
{
    const {t} = useTranslation();
    return (
        <div className={absoluteStyles.window_container} onClick={() => isDisableButtons ? null : close()} onTouchEnd={() => isDisableButtons ? null : close()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.screen_content}>
                        <h2>{title}</h2>
                        <span>{message}</span>
                        <div className={styles.screen_user_controls}>
                            <Button disabled={isDisableButtons} btnType="danger" onClick={() => close()}>{t("app.cancel")}</Button>
                            <Button disabled={isDisableButtons} onClick={() => confirm()}>{t("app.confirm")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmActionScreen;
