import React, {ChangeEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {deviceCompany, device} from "../../helpers/types";
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import _ from 'lodash';
import styles from './css/AddModifyType.module.css'
import Button from "../../utilities/Button";
import {addDeviceCompany, modifyDeviceCompanyByID} from "../../helpers/APIEnpoints";
import Context from "../../helpers/Context";
import {useTranslation} from "react-i18next";
import {inputValidation} from "../../helpers/helperFunctions";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useMsal} from "@azure/msal-react";

type defaultProps =
{
    setIsAddModifyCompany: Dispatch<SetStateAction<boolean>>

    deviceCompany: deviceCompany | null;
    setDeviceCompany: Dispatch<SetStateAction<deviceCompany | null>>;

    deviceCompanies: deviceCompany[];
    setDeviceCompanies: Dispatch<SetStateAction<deviceCompany[]>>;

    isAddModifyDevice?: boolean;
}

type addModifyDeviceProps =
{
    isAddModifyDevice: true;
    setNewModifyDevice: Dispatch<SetStateAction<device>>;
} |
{
    isAddModifyDevice?: false;
    setNewModifyDevice?: never;
}

type props = defaultProps & addModifyDeviceProps;

const AddModifyCompany: FC<props> = ({setIsAddModifyCompany, deviceCompanies, deviceCompany, setDeviceCompanies, setDeviceCompany, isAddModifyDevice= false, setNewModifyDevice}) =>
{
    const {notificationDispatch} = useContext(Context);

    const [newModifyDeviceCompany, setNewModifyDeviceCompany] = useState<deviceCompany>({_id: "", name: ""});

    const {t} = useTranslation();

    const [isChangesSaved, setIsChangesSaved] = useState<boolean>(true);

    const [isShowChangesNotSaved, setIsShowChangesNotSaved] = useState<boolean>(false);

    const [initialDeviceCompanyState, setInitialDeviceCompanyState] = useState<deviceCompany>({_id: "", name: ""});

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        if(deviceCompany !== null)
        {
            setNewModifyDeviceCompany(_.clone(deviceCompany));
        }
    }, []);

    useEffect(() =>
    {
        setIsChangesSaved((((deviceCompany && _.isEqual(deviceCompany, newModifyDeviceCompany)) || _.isEqual(newModifyDeviceCompany, initialDeviceCompanyState)) ));
    }, [newModifyDeviceCompany]);

    const handleClose = () =>
    {
        setIsAddModifyCompany(false);
        setNewModifyDeviceCompany({_id: "", name: ""});
        setDeviceCompany(null);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    {
        let { name, value } = e.target;

        setNewModifyDeviceCompany(prev => ({...prev, [name]: value}));
    };

    const handleDeviceCompanyAddModify = async () =>
    {
        if(inputValidation([{value: newModifyDeviceCompany.name, label: t("controlPanel.companyName")}], notificationDispatch))
        {
            if(deviceCompany)
            {
                const modifiedDeviceCompany = await modifyDeviceCompanyByID(newModifyDeviceCompany, notificationDispatch, {accounts: accounts, instance: instance});

                if(modifiedDeviceCompany)
                {
                    const index = deviceCompanies.findIndex(company => company._id === modifiedDeviceCompany._id);
                    setDeviceCompanies(current =>
                    {
                        current[index] = modifiedDeviceCompany;
                        return [...current];
                    });

                    handleClose();
                }
            } else
            {
                const addedDeviceCompany = await addDeviceCompany(newModifyDeviceCompany, notificationDispatch, {accounts: accounts, instance: instance});
                if(addedDeviceCompany)
                {
                    setDeviceCompanies(current => [...current, addedDeviceCompany]);

                    if(isAddModifyDevice)
                    {
                        setNewModifyDevice!(current => {
                            current.companyID = {_id: addedDeviceCompany._id, name: addedDeviceCompany.name};

                            return current
                        });
                    }

                    handleClose();
                }
            }
            setIsChangesSaved(true);
        }
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)} onTouchEnd={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)}>
            <div className={absoluteStyles.window_container_content} style={{padding: 0}} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.container_device_company}>
                        <label>
                            {t("controlPanel.companyName")}*
                            <input type="text" name="name" defaultValue={newModifyDeviceCompany.name} onChange={e => handleChange(e)} />
                        </label>

                        <div className={styles.controls_device_company}>
                            <Button btnType="danger" onClick={() => isChangesSaved ? handleClose() : setIsShowChangesNotSaved(true)}>{t("app.cancel")}</Button>
                            <Button onClick={handleDeviceCompanyAddModify}>{`${t("app.save")}${isChangesSaved ? "" : "*"}`}</Button>
                        </div>

                        {isShowChangesNotSaved &&
                            <ConfirmActionScreen
                                close={() => setIsShowChangesNotSaved(false)}
                                confirm={handleClose}
                                title={t("notSavedModal.title")}
                                message={
                                    <div className={styles.not_saved_message}>
                                        <span>{t("notSavedModal.warningMessage")}</span>
                                        <span style={{color: "var(--error)"}}><strong>{t("notSavedModal.warningMessageSubtitle")}</strong></span>
                                    </div>
                                }/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddModifyCompany;
