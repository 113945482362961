import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Types.module.css';
import {deviceCompany} from "../../helpers/types";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useTranslation} from "react-i18next";
import {upperCaseFirstLetter} from "../../helpers/helperFunctions";
import {deleteDeviceCompanyByID, getAllDeviceCompanies} from "../../helpers/APIEnpoints";
import AddModifyCompany from "./AddModifyCompany";
import {useMsal} from "@azure/msal-react";

function Companies({})
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const [deviceCompanies, setDeviceCompanies] = useState<deviceCompany[]>([]);
    const [deviceCompany, setDeviceCompany] = useState<deviceCompany | null>(null);

    const [isAddModifyCompany, setIsAddModifyCompany] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);

    const [isDeviceCompanyDeleting, setIsDeviceCompanyDeleting] = useState<boolean>(false);

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        if(isDelete || isAddModifyCompany)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[isDelete, isAddModifyCompany]);

    useEffect(() =>
    {
        (async () =>
        {
            const deviceCompanies= await getAllDeviceCompanies(notificationDispatch, {accounts: accounts, instance: instance});
            if(deviceCompanies) setDeviceCompanies(deviceCompanies);
        })();
    }, []);

    const handleDeviceCompanyEdit = (deviceCompany: deviceCompany) =>
    {
        setDeviceCompany(deviceCompany);
        setIsAddModifyCompany(true);
    }

    const onDelete = (deviceCompany: deviceCompany) =>
    {
        setDeviceCompany(deviceCompany);
        setIsDelete(true);
    }

    const handleDeviceCompanyDelete = async () =>
    {
        setIsDeviceCompanyDeleting(true);
        const deletedType = await deleteDeviceCompanyByID(deviceCompany!._id, notificationDispatch, {accounts: accounts, instance: instance});
        if(deletedType) setDeviceCompanies(current => current.filter(currCompany => currCompany._id !== deviceCompany!._id));

        setIsDeviceCompanyDeleting(false);

        handleDeleteClose();
    }

    const handleDeleteClose = () =>
    {
        setDeviceCompany(null);
        setIsDelete(false);
    }

    return (
        <div className={styles.container}>
            <div className={styles.top_controls}>
                <Button onClick={() => setIsAddModifyCompany(true)}>{t("app.add")}</Button>
            </div>


            <div className={styles.types_list}>
                {deviceCompanies.map(company =>
                    <div key={company._id} className={styles.type_card} onClick={() => handleDeviceCompanyEdit(company)}>
                        <span>{t("device.company")}: {upperCaseFirstLetter(company.name)}</span>

                        <div className={styles.controls}>
                            <Button btnMini={true} onClick={e => {e.stopPropagation(); onDelete(company);}} btnType="danger" iconType="delete">{t("app.delete")}</Button>
                            <Button btnMini={true} onClick={() => handleDeviceCompanyEdit(company)} iconType="edit">{t("app.edit")}</Button>
                        </div>
                    </div>
                    )}
            </div>

            {isAddModifyCompany &&
                <AddModifyCompany setIsAddModifyCompany={setIsAddModifyCompany}
                                  deviceCompany={deviceCompany}
                                  setDeviceCompany={setDeviceCompany}
                                  deviceCompanies={deviceCompanies}
                                  setDeviceCompanies={setDeviceCompanies}/>
            }

            {isDelete &&
                <ConfirmActionScreen close={handleDeleteClose} confirm={handleDeviceCompanyDelete} isDisableButtons={isDeviceCompanyDeleting}
                                     message=""
                                     title={`${t("controlPanel.delete")} "${deviceCompany?.name}" ${t("device.company").toLowerCase()}?`} />
            }
        </div>
    );
}

export default Companies;
