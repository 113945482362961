import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import styles from "./css/ToriPasswordChecker.module.css";
import Button from "../utilities/Button";
import {SignInButton} from "./SignInButton";
import {verifyToriPass} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import {useTranslation} from "react-i18next";

interface IProps
{
    setIsPasswordCorrect: Dispatch<SetStateAction<boolean>>;
}

const ToriPasswordChecker: FC<IProps> = ({setIsPasswordCorrect}) =>
{
    const {t} = useTranslation();

    const {notificationDispatch} = useContext(Context);
    const [providedPassword, setProvidedPassword] = useState<string>("");

    const handleToriPasswordVerification = async () =>
    {
        const passwordVerify = await verifyToriPass(providedPassword, notificationDispatch);

        if(passwordVerify)
        {
            setIsPasswordCorrect(true);
            sessionStorage.setItem("toriPassCorrect", "");
        }
    }

    const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) =>
    {
        if (['Enter', 'NumpadEnter', "Go"].includes(e.key)) handleToriPasswordVerification();
    }

    return (
        <div className={styles.container}>
            <h1>{t("toriPassCheck.welcome")}</h1>
            <h3>{t("toriPassCheck.authRequired")}</h3>
            <label>
                {t("toriPassCheck.enterThePass")}
                <input type="text" onKeyDown={e => handleKeyDownInput(e)} onChange={e => setProvidedPassword(e.target.value)}/>
                <Button onClick={handleToriPasswordVerification}>{t("app.enter")}</Button>
            </label>
            - {t("app.or")} -
            <SignInButton btnType="primary" />
        </div>
    );
}

export default ToriPasswordChecker;
