import React, {Dispatch, SetStateAction} from "react";
import {device, deviceCompany, photo} from "./types";
import {t} from "i18next";
import {notificationDispatch} from './types';
import {v4} from "uuid";
import {SetURLSearchParams} from "react-router-dom";
import DeviceCard from "../utilities/DeviceCard";
import {getDeviceImagesByID} from "./APIEnpoints";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {de} from "date-fns/locale";

export const resetFilter = (arrayFilters?: Dispatch<SetStateAction<any[]>>[], stringFilters?: Dispatch<SetStateAction<string>>[], searchParams?: URLSearchParams, setSearchParams?: SetURLSearchParams, params?: string[]) =>
{
    if(arrayFilters)
    {
        arrayFilters.map(filter => filter([]))
    }

    if(stringFilters)
    {
        stringFilters.map(filter => filter(""))
    }

    if(searchParams && setSearchParams && params?.length)
    {
        for(const param of params)
        {
            searchParams.set(param.toLowerCase(), "all");
        }

        setSearchParams(searchParams)
    }

}

export const blobToBase64 = (blob: Blob) =>
{
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve =>
    {
        reader.onloadend = () =>
        {
            resolve(reader.result);
        };
    });
};

export const upperCaseFirstLetter = (string: string) =>
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractFullNameFromEmail = (email: string) =>
{
    const fullName = email.split("@")[0];

    if(fullName.includes("."))
    {
        const firstName = fullName.split(".")[0];
        const lastName = fullName.split(".")[1];

        return `${upperCaseFirstLetter(firstName)} ${upperCaseFirstLetter(lastName)}`;
    }

    return upperCaseFirstLetter(fullName);
}

export const filterSortDevices = (devices: device[],
                                  filters: {selectedType: string, selectedStatus: string, searchQuery: string, selectedSpecs: {name: string, value: string}[], selectedCompany: string}, sortBy: string) =>
{
    const {selectedStatus, selectedSpecs, selectedType, searchQuery, selectedCompany} = filters;

    const specsFiltering: {deviceID: string, num: number, test: device}[] = [];

    return devices.filter(device => device.state !== "sold")
        .filter(device => selectedCompany ? (device.companyID as deviceCompany).name.toLowerCase() === selectedCompany.toLowerCase() : device)
        .filter(device => selectedType ? device.typeID.name.toLowerCase() === selectedType.toLowerCase() : device)
        .filter(device => selectedStatus ? device.state.toLowerCase() === selectedStatus.toLowerCase() : device)
        .filter(device => searchQuery ? device.model.toLowerCase().includes(searchQuery.toLowerCase()) : device)
        .filter(device =>
        {
            if(selectedSpecs.length)
            {
                let matches = 0;
                for(const spec of selectedSpecs)
                {
                    if(device.specs[spec.name as keyof Object] !== undefined && device.specs[spec.name as keyof Object].toLowerCase() === spec.value.toLowerCase())
                    {
                        matches++;
                    }
                }

                specsFiltering.push({deviceID: device._id, num: matches, test: device});
            }

            return device;
        }).filter(device => selectedSpecs.length ?
            specsFiltering.filter(specFilter => specFilter.num === [...new Set(selectedSpecs.map(spec => spec.name))].length)
                .filter(specFilter => specFilter.deviceID.toLowerCase() === device._id.toLowerCase()).length : device)
        .sort((a, b) =>
        {
            switch (sortBy)
            {
                case "nameAZ":
                    return a.model.localeCompare(b.model, "fi");
                case "nameZA":
                    return b.model.localeCompare(a.model, "fi");
                case "statusAZ":
                    return a.state.localeCompare(b.state, "fi");
                case "statusZA":
                    return b.state.localeCompare(a.state, "fi");
                case "leasingAsc":
                    return new Date(a.leasingEndDate).getTime() - new Date(b.leasingEndDate).getTime();
                case "leasingDesc":
                    return new Date(b.leasingEndDate).getTime() - new Date(a.leasingEndDate).getTime();
                default:
                    return 0;
            }
        });
}

export const filterDevicesMultiSelectorSpecs = (devices: device[],
                                                filters: {selectedType: string, selectedStatus: string, searchQuery: string, selectedSpecs: {name: string, value: string}[], properTypeSpecsLength: number, selectedCompany: string}) =>
{
    const {selectedStatus, selectedType, searchQuery, properTypeSpecsLength , selectedSpecs, selectedCompany} = filters;

    return devices.filter(device => device.state !== "sold")
        .filter(device => selectedCompany ? (device.companyID as deviceCompany).name.toLowerCase() === selectedCompany.toLowerCase() : device)
        .filter(device => selectedType ? device.typeID.name.toLowerCase() === selectedType.toLowerCase() : device)
        .filter(device => selectedStatus ? device.state.toLowerCase() === selectedStatus.toLowerCase() : device)
        .filter(device => searchQuery ? device.model.toLowerCase().includes(searchQuery.toLowerCase()) : device)
}


export const inputValidation = (inputsToValidate: {value: string, label: string}[], notificationDispatch: Dispatch<notificationDispatch>) =>
{
    for (const input of inputsToValidate)
    {
        if(input.value === undefined || !input.value.trim().length)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: `${input.label}${t("inputValidation.emptyError")}`
                    }
            });
        }
    }

    return true;
}

export const handleDeviceStateTranslation = (state: string): string =>
{
    switch (state)
    {
        case "free":
            return t("device.free")
        case "available":
            return t("device.available")
        case "reserved":
            return t("device.reserved")
        case "sold":
            return t("device.sold")
        default:
            return t("device.unknown")
    }
}

export const handleDecisionTranslate = (decision: string) =>
{
    switch (decision)
    {
        case "accepted":
            return t("app.accepted")
        case "pending":
            return t("app.pending")
        case "rejected":
            return t("app.rejected")
    }
}

export const getPhotoIndex = (photoArray: photo[], deviceID: string) =>
{
    return photoArray.findIndex(photo => (photo.deviceID.toLowerCase() === deviceID.toLowerCase()) );
}

export const getDevicePhotos = async (device: device, arrayToPopulate: photo[], setArrayToPopulate: Dispatch<SetStateAction<photo[]>>, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication})=>
{
    const index = getPhotoIndex(arrayToPopulate, device._id);
    const {accounts, instance} = token;

    if(index === -1)
    {
        const deviceImages = await getDeviceImagesByID(device._id, notificationDispatch, {accounts: accounts, instance: instance});
        if(deviceImages)
        {
            setArrayToPopulate(current => [...current, {deviceID: device._id, images: deviceImages}])
            return;
        }
    } else if(device.images.length !== arrayToPopulate[index].images.length)
    {
        const deviceImages = await getDeviceImagesByID(device._id, notificationDispatch, {accounts: accounts, instance: instance});
        if(deviceImages)
        {
            setArrayToPopulate(current =>
            {
                current[index].images = deviceImages;
                return current;
            })
            return;
        }
    }
}
