import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Device.module.css';
import {device, deviceCompany, deviceImage, deviceType} from "../helpers/types";
import {useParams} from "react-router-dom";
import Context from "../helpers/Context";
import {getDeviceByID, getDeviceImagesByID} from "../helpers/APIEnpoints";
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import {Autoplay, EffectFade, FreeMode, Navigation, Thumbs} from "swiper/modules";
import Button from "../utilities/Button";
import Loader from "../utilities/Loader";
import {Trans, useTranslation} from "react-i18next";
import {
    getDevicePhotos,
    getPhotoIndex,
    handleDeviceStateTranslation,
    upperCaseFirstLetter
} from "../helpers/helperFunctions";
import DeviceRequestModal from "../utilities/DeviceRequestModal";
import {Icons} from "../helpers/Icons";
import {
    TransformComponent,
    TransformWrapper
} from "react-zoom-pan-pinch";
import {useMsal} from "@azure/msal-react";
import FirstTimeBanner from "../utilities/FirstTimeBanner";

function Device({})
{
    const {t} = useTranslation();
    const {accounts, instance} = useMsal();

    const {notificationDispatch, devicePhotos, setDevicePhotos} = useContext(Context);
    const [device, setDevice] = useState<device | null>(null);
/*
    const [deviceImages, setDeviceImages] = useState<deviceImage[]>([]);
*/

    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>(null);

    const {id} = useParams();

    const [isRequestClicked, setIsRequestClicked] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isRequestMade, setIsRequestMade] = useState<boolean>(false);

    const [isShowBanner, setIsShowBanner] = useState<boolean>(true);

    useEffect(() =>
    {
        if(localStorage.getItem("bannerHidden") && !sessionStorage.getItem("hideBannerSession"))
        {
            setIsShowBanner(localStorage.getItem("bannerHidden") !== "1");
        } else if(sessionStorage.getItem("hideBannerSession")) setIsShowBanner(false)
    }, []);

    useEffect(() =>
    {
        if(isRequestClicked || isShowBanner)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[isRequestClicked, isShowBanner]);

    const handleCloseRequestModal = () =>
    {
        setIsRequestClicked(false);
    }

    useEffect(() =>
    {
        (async () =>
        {
            if(id)
            {
                const device = await getDeviceByID(id, notificationDispatch, {accounts: accounts, instance: instance});
/*                const deviceImages = await getDeviceImagesByID(id, notificationDispatch, {accounts: accounts, instance: instance});

                if(deviceImages) setDeviceImages(deviceImages);*/
                if(device)
                {
                    setDevice(device);
                    await getDevicePhotos(device, devicePhotos, setDevicePhotos, notificationDispatch, {accounts: accounts, instance: instance});
                }
            }
            setIsLoading(false);
            setIsRequestMade(false);
        })();

    }, [id, isRequestMade]);

    const handleResetTransform = () =>
    {
        const elements = document.getElementsByClassName("react-transform-component");

        for(const el of elements)
        {
            (el as HTMLDivElement).style.transform = "translate(0px, 0px)";
        }
    }

    return (
        <div className={styles.main_container}>
            {(!isLoading && device) ?
                <div className={styles.device_container}>
                    <div className={styles.top_container}>
                        {devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.length ?
                            <div className={styles.swipers_container}>
                                <Swiper
                                    modules={[FreeMode, EffectFade, Thumbs, Autoplay]}
                                    loop={devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.length > 1}
                                    autoplay={{delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true}}
                                    initialSlide={0}
                                    allowTouchMove={false}
                                    effect={"fade"}
                                    onSlideChangeTransitionEnd={() => handleResetTransform()}
                                    thumbs={{ swiper: (thumbsSwiper && !thumbsSwiper.destroyed) ? thumbsSwiper : null }}
                                    className={styles.images_swiper}>

                                    {devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.map(image =>
                                        <SwiperSlide key={image._id} className={styles.image_slide}>
                                            <TransformWrapper disablePadding={true}>
                                                <TransformComponent contentClass={styles.transformContent} wrapperClass={styles.transformWrapper}>
                                                    <img loading="lazy" src={image.image} alt={device.model}/>
                                                </TransformComponent>
                                            </TransformWrapper>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={3.5}
                                    initialSlide={0}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    navigation={devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.length > 3}
                                    modules={[FreeMode, Navigation, Thumbs, Navigation]}
                                    className={`${styles.thumbs_swiper} thumbsSwiper image_slide_active`}>

                                    {devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.map(image =>
                                        <SwiperSlide key={image._id} className={styles.image_slide_thumbs}>
                                            <img loading="lazy" src={image.image} alt={device.model} />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </div>
                        : <div className={styles.image_placeholder}>{Icons.image}</div>}

                        <div className={styles.general_info}>
                            <div className={styles.top}>
                                <span className={styles.header}>{device.model}</span>
                                <span className={styles.type}>{upperCaseFirstLetter((device.typeID as deviceType).name)}</span>
                            </div>
                            <div className={styles.info}>
                                {device.description.length ?
                                    <p className={styles.description}>{device.description}</p>
                                    :
                                    <span className={styles.no_description}>{t("device.noDescriptionFound")}</span>
                                }
                                <div className={styles.span_text}><span>ID: </span>{device.toriID}</div>
                                <div className={`${styles.span_text} ${styles.company_container}`}><span>{t("device.company")}: </span>
                                    <div className={`${styles.company_tag} ${styles[`company_${(device.companyID as deviceCompany).name.toLowerCase()}`] ?? styles.company_default}`}> {Icons[(device.companyID as deviceCompany).name.toLowerCase() as keyof Object] ?
                                    <>{Icons[(device.companyID as deviceCompany).name.toLowerCase() as keyof Object]}</>
                                    : upperCaseFirstLetter((device.companyID as deviceCompany).name)}
                                </div></div>
                                <div className={styles.span_text}><span>{t("device.status")}</span>: <span className={`${styles.state} ${styles[device.state]}`}>{handleDeviceStateTranslation(device.state)}</span></div>
                                <span><Trans i18nKey={`device.disclaimer${upperCaseFirstLetter(device.state)}`} /></span>
                                {device.state !== "free" && <span>{t("device.leasingEndDate")}: {(device.leasingEndDate && device.leasingEndDate.length) ? device.leasingEndDate : t("app.unknown").toLowerCase()}</span>}
                            </div>
                            {(!["reserved", "sold"].includes(device.state) && !device.requests.length) ?
                                <div className={styles.controls}>
                                    <Button onClick={() => setIsRequestClicked(true)}>{t("device.request")}</Button>
                                </div>
                            : null}
                        </div>
                    </div>

                    {(device.typeID as deviceType).typeSpecs.length ?
                    <div className={styles.specs_container}>
                        <h2>{t("device.specs")}</h2>
                        <div className={styles.specs_list}>
                            {(device.typeID as deviceType).typeSpecs.map(spec =>
                                <div key={spec._id} className={styles.spec}><span className={styles.spec_label}>{spec.name}:</span> {device.specs[spec.name as keyof Object]}</div>
                            )}
                        </div>
                    </div>
                    : null}

                </div>
            :
              <div className={styles.loading_not_found_container}>{isLoading ? <Loader /> : <p>{t("backEnd.deviceNotFound")}</p>}</div>
            }

            {isRequestClicked && device &&
                <DeviceRequestModal device={device} handleClose={handleCloseRequestModal} setIsRequestMade={setIsRequestMade}/>
            }

            {isShowBanner && <FirstTimeBanner setIsShown={setIsShowBanner} />}

        </div>
    );
}

export default Device;
