import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {device, deviceCompany, deviceImage} from "../helpers/types";
import styles from './css/DeviceCard.module.css'
import Button from "./Button";
import {getDeviceImagesByID} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import {Autoplay, EffectFade, Pagination} from "swiper/modules";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    getDevicePhotos,
    getPhotoIndex,
    handleDeviceStateTranslation,
    upperCaseFirstLetter
} from "../helpers/helperFunctions";
import {Icons} from "../helpers/Icons";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {useMsal} from "@azure/msal-react";

type defaultProps =
{
    device: device;
    isControlPanel?: boolean;
}

type tControlPanel =
{
    isControlPanel: true;
    onDelete: (device: device, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    onEdit: (device: device) => void;
    onRequest?: never;
} |
{
    isControlPanel?: boolean;
    onDelete?: never;
    onEdit?: never;
    onRequest: Dispatch<SetStateAction<device | null>>;
}

type props = defaultProps & tControlPanel;

const DeviceCard: FC<props> = ({device, isControlPanel = false, onDelete, onEdit, onRequest}) =>
{
    const {t} = useTranslation();
    const {notificationDispatch, devicePhotos, setDevicePhotos} = useContext(Context);
/*
    const [deviceImages, setDeviceImages] = useState<deviceImage[]>([]);
*/

    const navigate = useNavigate();

    const [isSkeleton, setIsSkeleton] = useState<boolean>(true);

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        (async () =>
        {
/*
            const deviceImages = await getDeviceImagesByID(device._id, notificationDispatch, {accounts: accounts, instance: instance});
            if(deviceImages) setDeviceImages(deviceImages);
*/

            await getDevicePhotos(device, devicePhotos, setDevicePhotos, notificationDispatch, {accounts: accounts, instance: instance});

            setIsSkeleton(false);
        })();
    }, [device]);

    return (
        <div className={`${isControlPanel ? styles.container_control_panel : styles.container} ${isControlPanel ? null : styles[device.state]}`} onClick={() => isControlPanel ? onEdit!(device) : navigate(`/device/${device._id}`)}>
            <div className={`${styles.company_tag} ${styles[`company_${(device.companyID as deviceCompany).name.toLowerCase()}`] ?? styles.company_default}`}> {Icons[(device.companyID as deviceCompany).name.toLowerCase() as keyof Object] ?
                <>{Icons[(device.companyID as deviceCompany).name.toLowerCase() as keyof Object]}</>
                : upperCaseFirstLetter((device.companyID as deviceCompany).name)}
            </div>
            {(!isSkeleton && devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.length) ?
            <Swiper
                modules={[Pagination, EffectFade, Autoplay]}
                pagination={{clickable: true}}
                autoplay={{delay: 5000, disableOnInteraction: false}}
                initialSlide={0}
                effect={"fade"}
                className={styles.images_swiper}>

                {devicePhotos[getPhotoIndex(devicePhotos, device._id)].images.map(image =>
                    <SwiperSlide key={image._id} className={styles.image_slide}>
                        <img loading="lazy" src={image.image} alt={device.model} />
                    </SwiperSlide>
                )}
            </Swiper>
                : isSkeleton ? <Skeleton containerClassName={styles.images_swiper} height="100%" width="100%" /> : <div className={styles.image_placeholder}>{Icons.image}</div>
            }

            <div className={styles.content}>
                <div className={styles.general_info}>

                    <div className={styles.top}>
                        {!isControlPanel && <span className={styles.toriID}>ID: {device.toriID}</span>}
                        <span className={styles.header}>{device.model}</span>
                        <span className={styles.type}>{upperCaseFirstLetter(device.typeID.name)}</span>
                    </div>

                    <div className={`${styles.info_content} ${isControlPanel ? styles.info_content_default : null}`}>
                        {!isControlPanel && <div className={styles.description_container}>
                            {device.description.length ?
                                <span className={styles.description}>{device.description}</span>
                            :
                                <span className={styles.no_description}>{t("device.noDescriptionFound")}</span>
                            }
                        </div>}
                        {isControlPanel && <span>ID: {device.toriID}</span>}
                        <span>{t("device.status")}: {handleDeviceStateTranslation(device.state)}</span>
                        {device.state !== "free" && <span className={styles.leasingEndDate}>{t("device.leasingEndDate")}: {(device.leasingEndDate && device.leasingEndDate.length) ? device.leasingEndDate : t("app.unknown").toLowerCase()}</span>}
                        {isControlPanel && <span>{t("controlPanel.requests")}: {device.requests.length}</span>}
                    </div>
                </div>
            </div>

            <div className={styles.controls}>
                {isControlPanel ?
                    <>
                        <Button onClick={e => onDelete!(device, e)} btnType="danger" iconType="delete">{t("app.delete")}</Button>
                        <Button onClick={() => onEdit!(device)} iconType="edit">{t("app.edit")}</Button>
                    </>
                    : !isControlPanel && (!["reserved", "sold"].includes(device.state) && !device.requests.length) &&
                    <Button onClick={e =>
                    {
                        e.stopPropagation();
                        onRequest!(device)
                    }}>{t("device.request")}</Button>
                }
            </div>
        </div>
    );
}

export default DeviceCard;
