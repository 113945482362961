import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import styles from './css/MultiSelector.module.css';
import {Icons} from "../helpers/Icons";
import {useTranslation} from "react-i18next";
import {upperCaseFirstLetter} from "../helpers/helperFunctions";
import {useSearchParams} from "react-router-dom";
import {da} from "date-fns/locale";

type defaultProps =
{
    isMulti?: boolean;
    isOptionAll?: boolean;
    isSearchAvailable?: boolean;
    isApplyLocale?: boolean;
    width?: string;
    isQueryParams?: boolean;
    isDisabled?: boolean;

    label: string;
    data: string[];
}

type multiProps =
{
    isMulti: true;
    isObjectMulti?: boolean
    setSelectedOptions: Dispatch<SetStateAction<string[]>> | Dispatch<SetStateAction<{name: string, value: string}[]>>;
    selectedOptions: string[] | {name: string, value: string}[];
} |
{
    isMulti?: false;
    isObjectMulti?: never;
    selectedOptions: string;
    setSelectedOptions: Dispatch<SetStateAction<string>>;
}

type searchQueryProps =
{
    isQueryParams: true;
    queryLabel: string;
} |
{
    isQueryParams?: false;
    queryLabel?: never;
}

type props = defaultProps & multiProps & searchQueryProps;

const MultiSelector: FC<props> = ({label, data, setSelectedOptions, isMulti = false, selectedOptions, isOptionAll = true, isObjectMulti = false, isSearchAvailable = true, isApplyLocale = false, width, isQueryParams = false, queryLabel, isDisabled = false}) =>
{
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const {t} = useTranslation();

    let [searchParams, setSearchParams] = useSearchParams();


    useEffect(() =>
    {
        if(showOptions)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[showOptions]);

    useEffect(() =>
    {
        document.addEventListener('mousedown', (event) =>
        {
            if (ref.current && !ref.current.contains(event.target as Node)) setShowOptions(false);
        });

        document.addEventListener('touchend', (event) =>
        {
            if (ref.current && !ref.current.contains(event.target as Node)) setShowOptions(false);
        });
    }, [ref]);

    const handleOptionSelect = (name: string, option: string) =>
    {
        if(isQueryParams)
        {
            if(!searchParams.has(queryLabel!.toLowerCase())) searchParams.append(queryLabel!.toLowerCase(), option.toLowerCase());
            searchParams.set(queryLabel!.toLowerCase(), option.toLowerCase());
            setSearchParams(searchParams)
        }

        if(isMulti)
        {
            if(isObjectMulti)
            {
                (setSelectedOptions as Dispatch<SetStateAction<{name: string, value: string}[]>>) (current => [...current, {name: name, value: option}]);
            } else (setSelectedOptions as Dispatch<SetStateAction<string[]>>) (current => [...current, option]);
            return;
        }

        (setSelectedOptions as Dispatch<SetStateAction<string>>)(option);
    }

    const handleAllOption = () =>
    {
        if(isQueryParams)
        {
            searchParams.set(queryLabel!.toLowerCase(), "all");
            setSearchParams(searchParams)
        }

        if(isMulti)
        {
            (setSelectedOptions as Dispatch<SetStateAction<string[]>>)([]);
            return;
        }

        (setSelectedOptions as Dispatch<SetStateAction<string>>)("");
    }

    const handleProperLabel = () =>
    {
        if(selectedOptions.length)
        {
            if(!isMulti) return upperCaseFirstLetter(isApplyLocale ? t(`app.${selectedOptions as string}`) : selectedOptions as string);
            if(isMulti && !isObjectMulti) return <span className={styles.chosen}>{t("app.chosen")}</span>;
            if(isMulti && isObjectMulti)
            {
                if((selectedOptions as {name: string, value: string}[]).filter(option => option.name === label).length) return <span className={styles.chosen}>Chosen</span>
                else return t("app.all")
            }
        }

        return t("app.all")
    }

    const handleMultiSelectDelete = (optionToDelete: string) =>
    {
        if(isObjectMulti)
        {
            (setSelectedOptions as Dispatch<SetStateAction<{name: string, value: string}[]>>)(current => current.filter(spec => spec.value !== optionToDelete));
            return;
        }

        (setSelectedOptions as Dispatch<SetStateAction<string[]>>)(current => current.filter(option => option !== optionToDelete));
    }

    return (
        <div className={styles.container} ref={ref} style={{width: width ? width : undefined}}>
            <span className={styles.label}>{upperCaseFirstLetter(label)}</span>

            <div className={`${styles.selector} ${showOptions ? styles.show_options : null} ${isDisabled ? styles.disabled : null}`} onClick={() => setShowOptions(current => !current)}>
                <div className={styles.selector_face}>
                    {(showOptions && isSearchAvailable) ? <input autoFocus={showOptions && window.innerWidth > 500}
                                          placeholder={t("app.search")} onChange={e => setSearchQuery(e.target.value)}
                                          onClick={e => e.stopPropagation()} type="search" /> : handleProperLabel()}
                    <button>{showOptions ? Icons.arrowUp : Icons.arrowDown}</button>
                </div>

                {showOptions &&
                    <div className={styles.options_container_main}>
                        <div className={styles.options_container}>
                            {isOptionAll ? <span className={`${styles.option} ${!selectedOptions.length ? styles.selected : null}`} onClick={handleAllOption}>{t("app.all")}</span> : null}
                            {data.filter(option => searchQuery ? option.toLowerCase().includes(searchQuery.toLowerCase()) : option)
                                .filter(option => selectedOptions.length ?
                                    isMulti && isObjectMulti ?
                                        !(selectedOptions as {name: string, value: string}[])
                                            .filter(selectedOption => selectedOption.name === label && selectedOption.value === option).length :
                                        isMulti && !isObjectMulti ? !(selectedOptions as string[]).includes(option) : option

                                    : option)
                                .map((option, index) =>
                                        <span key={index} className={`${styles.option} 
                            ${(selectedOptions.length && !isMulti && (selectedOptions as string).toLowerCase() === option.toLowerCase()) ? styles.selected : null}`}
                                              onClick={() => handleOptionSelect(label, option)}>
                                {upperCaseFirstLetter(isApplyLocale ? t(`app.${option}`) : option)}
                            </span>
                                )}
                        </div>
                    </div>
                }

            </div>

            {isMulti && isObjectMulti && (selectedOptions as {name: string, value: string}[]).filter(option => option.name === label).length ?
                <div className={styles.selected_options_container}>
                    {(selectedOptions as {name: string, value: string}[])
                        .filter(option => option.name === label)
                        .map((option, index) =>
                        <div key={index} className={`${styles.selected_option}`}
                                         onClick={() => handleMultiSelectDelete(option.value)}>
                            <span>{upperCaseFirstLetter(option.value)}</span>
                            <span className={styles.delete_selected}>{Icons.crossDelete}</span>
                </div>
                    )}
                </div>
                : null}

            {isMulti && !isObjectMulti ?
                <div className={styles.selected_options_container}>
                    {(selectedOptions as string[]).map((option, index) =>
                            <div key={index} className={`${styles.selected_option}`}
                                 onClick={() => handleMultiSelectDelete(option)}>
                                <span>{option}</span>
                                <span className={styles.delete_selected}>{Icons.crossDelete}</span>
                            </div>
                        )}
                </div>
                : null}
        </div>
    );
}

export default MultiSelector;
