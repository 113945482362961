import React, {FC} from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {useTranslation} from "react-i18next";
import Button from "../utilities/Button";
import styles from './css/SignInOutButtons.module.css';

interface IProps
{
    btnType?: string;
}

export const SignInButton: FC<IProps> = ({btnType= "ghost"}) =>
{
    const { instance } = useMsal();

    const {t} = useTranslation();

    const handleLogin = () =>
    {
            instance.loginRedirect(loginRequest).catch(e =>
            {
                console.log(e);
            });
    }

    return (
        <Button className={styles.btn} btnType={btnType} onClick={handleLogin}>{t("app.signIn")}</Button>
    );
}
