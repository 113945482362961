import React, {FC, ReactNode, useEffect, useRef} from 'react';
import styles from './css/Button.module.css';
import {Icons} from "../helpers/Icons";
import {Link} from "react-router-dom";

interface IProps
{
    children: ReactNode;
    btnType?: string;
    iconType?: string;
    iconDirection?: string;
    href?: string;
    onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)
    disabled?: boolean;
    className?: string;
    btnMini?: boolean;
}

const Button: FC<IProps> = ({children, btnType = "primary", iconType = "none", iconDirection = "right", href, onClick, disabled = false, className, btnMini = false}) =>
{
    const ref = useRef<HTMLAnchorElement>(null);

    const properHref = disabled ? "" : href ? href : "";

    return (
        <Link to={properHref} onClick={disabled ? () => null : onClick} className={`${styles.button} ${disabled && btnType !== "danger" ? styles.disabled : disabled && btnType === "danger" ? styles.danger_disabled : styles[btnType]} ${btnMini ? styles.btn_mini : ""}`}>
            <div style={{order: iconDirection === "right" ? 1 : 2}} className={`${styles.text} ${className}`}>{children}</div>
            {iconType !== "none" && <div style={{order: iconDirection === "right" ? 2 : 1}} className={styles.icon}>{Icons[iconType as keyof object]}</div>}
        </Link>
    );
}

export default Button;
