import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Types.module.css';
import {deleteTypeByID, getAllDeviceTypes} from "../../helpers/APIEnpoints";
import {deviceType} from "../../helpers/types";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import AddModifyType from "./AddModifyType";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {useTranslation} from "react-i18next";
import {upperCaseFirstLetter} from "../../helpers/helperFunctions";
import {useMsal} from "@azure/msal-react";

function Types({})
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const [deviceTypes, setDeviceTypes] = useState<deviceType[]>([]);
    const [type, setType] = useState<deviceType | null>(null);

    const [isAddModifyType, setIsAddModifyType] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);

    const [isTypeDeleting, setIsTypeDeleting] = useState<boolean>(false);

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        if(isDelete || isAddModifyType)
        {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";
        } else
        {
            document.body.style.overflow = "";
            document.body.style.touchAction = "auto";
        }

    },[isDelete, isAddModifyType]);

    useEffect(() =>
    {
        (async () =>
        {
            const types= await getAllDeviceTypes(notificationDispatch, {accounts: accounts, instance: instance});
            if(types)
            {
                setDeviceTypes(types);
            }
        })();
    }, []);

    const handleTypeEdit = (type: deviceType) =>
    {
        setType(type);
        setIsAddModifyType(true);
    }

    const onDelete = (type: deviceType) =>
    {
        setType(type);
        setIsDelete(true);
    }

    const handleTypeDelete = async () =>
    {
        setIsTypeDeleting(true);
        const deletedType = await deleteTypeByID(type!._id, notificationDispatch, {accounts: accounts, instance: instance});
        if(deletedType)
        {
            setDeviceTypes(current => current.filter(currType => currType._id !== type!._id));
        }

        setIsTypeDeleting(false);

        handleDeleteClose();
    }

    const handleDeleteClose = () =>
    {
        setType(null);
        setIsDelete(false);
    }

    return (
        <div className={styles.container}>
            <div className={styles.top_controls}>
                <Button onClick={() => setIsAddModifyType(true)}>{t("app.add")}</Button>
            </div>


            <div className={styles.types_list}>
                {deviceTypes.map(type =>
                    <div key={type._id} className={styles.type_card} onClick={() => handleTypeEdit(type)}>
                        <span>{t("device.type")}: {upperCaseFirstLetter(type.name)}</span>
                        <span>{t("device.specs")}: {type.typeSpecs.length}</span>

                        <div className={styles.controls}>
                            <Button btnMini={true} onClick={e => {e.stopPropagation(); onDelete(type);}} btnType="danger" iconType="delete">{t("app.delete")}</Button>
                            <Button btnMini={true} onClick={() => handleTypeEdit(type)} iconType="edit">{t("app.edit")}</Button>
                        </div>
                    </div>
                    )}
            </div>

            {isAddModifyType &&
                <AddModifyType setIsAddModifyType={setIsAddModifyType}
                               type={type}
                               setType={setType}
                               types={deviceTypes}
                               setTypes={setDeviceTypes}/>
            }

            {isDelete &&
                <ConfirmActionScreen close={handleDeleteClose} confirm={handleTypeDelete} isDisableButtons={isTypeDeleting}
                                     message=""
                                     title={`${t("controlPanel.delete")} ${type?.name} ${t("device.type").toLowerCase()}?`} />
            }
        </div>
    );
}

export default Types;
