import React, {useContext, useEffect, useState} from 'react';
import styles from './css/User.module.css';
import {deviceRequests} from "../helpers/types";
import {getUserRequests} from "../helpers/APIEnpoints";
import Context from "../helpers/Context";
import RequestCard from "../utilities/RequestCard";
import MultiSelector from "../utilities/MultiSelector";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

function User()
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const [requests, setRequests] = useState<deviceRequests[]>([]);

    const [stateFilter, setStateFilter] = useState<string>("");

    const {accounts, instance} = useMsal();

    useEffect(() =>
    {
        (async () =>
        {
            const requests = await getUserRequests(notificationDispatch, {accounts: accounts, instance: instance});
            if(requests) setRequests(requests);
        })();
    }, []);

    return (
        <div className={styles.container}>
            <MultiSelector data={["rejected", "pending", "accepted"]} width="254px" isApplyLocale={true}
                           label={t("device.status")}
                           selectedOptions={stateFilter}
                           setSelectedOptions={setStateFilter} />

            <div className={styles.requests_list}>
                {requests.filter(request => stateFilter.length ? request.state.toLowerCase() === stateFilter.toLowerCase() : request).length ?
                    requests.filter(request => stateFilter.length ? request.state.toLowerCase() === stateFilter.toLowerCase() : request).map(request =>
                    <RequestCard key={request._id} request={request} setRequests={setRequests} requests={requests} />
                ): <p>{t("app.noResults")}</p>}
            </div>
        </div>
    );
}

export default User;
